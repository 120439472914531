import { StyleSheet, TouchableHighlight } from 'react-native';
import {
  ScrollView,
  HStack,
  Text,
  Spacer,
  Button,
  ChevronDownIcon,
  InfoOutlineIcon,
  ChevronUpIcon,
  Avatar,
  Box,
  Stack,
  Center,
  Divider,
  PresenceTransition,
} from 'native-base';
import Octicons from "@expo/vector-icons/Octicons";
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from 'src/navigator';
import { useState } from 'react';

// create a component
export function Servicio({ servicio }: any) {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [showList, setShowList] = useState<any>(false);

  return (
    <ScrollView>
      <Box style={styles.boxList}>
        <TouchableHighlight
          underlayColor="transparent"
          onPress={() => setShowList(!showList)}
        >
          <HStack space={[2, 3]} paddingY={2} justifyContent="space-between">
            <Avatar source={{ uri: servicio.icon }} size="md" />
            <Text style={styles.titleList}>{servicio.name.toUpperCase()}</Text>
            <Spacer />
            {showList ? (
              <ChevronUpIcon size="5" color="gray.900" marginTop={4} />
            ) : (
              <ChevronDownIcon size="5" color="gray.900" marginTop={4} />
            )}
          </HStack>
        </TouchableHighlight>
      </Box>
      {showList &&
        servicio.servicios.map((servicioItem: any) => (
          <Box key={servicioItem.id}>
            <Divider px="10" />
            <TouchableHighlight
              underlayColor="transparent"
              onPress={() =>
                navigation.navigate('servicio-detail', {
                  link: servicioItem.link,
                  title: servicioItem.title,
                })
              }
            >
              <PresenceTransition
                visible={showList}
                initial={{
                  opacity: 0,
                  scale: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 250,
                  },
                }}
              >
                <Stack direction="row" mb="2.5" mt="1.5" px={3} space={[2, 4]}>
                  <Center flex={8}>
                    <Text style={styles.titleItem}>{servicioItem.title}</Text>
                  </Center>
                  <Spacer />
                  <Center>
                    <Button
                      style={styles.buttonList}
                      onPress={() =>
                        navigation.navigate('servicio-detail', {
                          link: servicioItem.link,
                          title: servicioItem.title,
                        })
                      }

                    >
                      <Octicons name="plus" color="#5299C1" size={30} />
                    </Button>
                  </Center>
                </Stack>
              </PresenceTransition>
            </TouchableHighlight>
          </Box>
        ))}
    </ScrollView>
  );
}

// define your styles
const styles = StyleSheet.create({
  boxList: {
    paddingHorizontal: 10,
    marginVertical: 3,
  },
  titleItem: {
    paddingVertical: 6,
    paddingHorizontal: 4,
    textAlign: 'center',
    color: 'black',
  },
  titleList: {
    paddingTop: 16,
    paddingLeft: 6,
    color: 'black',
  },
  buttonList: {
    color: 'black',
    backgroundColor: 'transparent',
  },
});
