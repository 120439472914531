import {
  Stack,
  View,
  ScrollView,
  useMediaQuery,
  useBreakpointValue,
} from "native-base";
import { FooterWebMobile } from "./footer-web-mobile";
import { HeaderWeb } from "./header-web";
import { SidebarContent } from "./sidebar-web";
import { getHeightFromPercentage } from "src/utils";
import { Platform } from "react-native";

import { RouteProp, useRoute } from "@react-navigation/native";
import { RootStackParamList } from "src/navigator";
import { FooterNative } from "./footer-native";

export function LayoutAuthenticated({ children }: any) {
  const route = useRoute<RouteProp<RootStackParamList>>();
  const [isSmallScreen] = useMediaQuery({
    minWidth: 1024,
  });
  const scrollViewPadding = useBreakpointValue({
    base: "0",
    lg: "4",
    xl: "4",
  });

  const directionStructure = useBreakpointValue({
    base: "column",
    lg: isSmallScreen ? "row" : "column",
    xl: "row",
  });

  return (
    <View 
      h={"full"}
      py={0}
    >
      <Stack
        h={getHeightFromPercentage(100)}
        direction={directionStructure}
        justifyContent="space-between"
      >
        {isSmallScreen ? <SidebarContent /> : <HeaderWeb />}
        <ScrollView
          h={getHeightFromPercentage(100)}
          showsVerticalScrollIndicator={false}
          px={scrollViewPadding}
          bg="white"
          pt={scrollViewPadding}
          pb={{ base: "16", lg: "4", xl: "4" }}
        >
          <View
            mb={
              Platform.OS === "web"
                ? "none"
                : route.name !== "home"
                ? "32"
                : "16"
            }
            bg="white"
          >
            {children}
          </View>
        </ScrollView>
      </Stack>
      {!isSmallScreen && <FooterWebMobile />}
      {Platform.OS !== "web" && <FooterNative />}
    </View>
  );
}
