interface ErrorData {
    message: string;
    data: {
        [key: string]: string[];
    };
}

export function extractErrorMessages(errorData: ErrorData): string[] {
    const errorMessages: string[] = [];

    if (errorData && errorData.data) {
        Object.keys(errorData.data).forEach((field) => {
            errorData.data[field].forEach((message: any) => {
                errorMessages.push(message);
            });
        });
    }

    return errorMessages;
}
