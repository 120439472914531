import { Box, Text, View, Flex, Pressable, Image } from "native-base";

export function UtilLinks({ title, image, handleClick }: any) {
  return (
    <Pressable onPress={handleClick}>
      <Flex
        w={{ base: 300, xl: 300, "2xl": 380 }}
        h={150}
        bg="white"
        rounded="md"
        px="2"
        mt={{ base: "0", md: "6", lg: "6" }}
        shadow={8}
        direction="row"
        align="center"
        justify="space-around"
      >
        <View>
          <Image
            source={image}
            w={70}
            h={70}
            alt="utiles image"
            alignItems="center"
            justifyContent="center"
          />
        </View>
        <Box>
          <Text
            fontSize={{ base: "md", xl: "md", "2xl": "xl", }}
            fontWeight="semibold"
            color="#004c35"
          >
            {title}
          </Text>
        </Box>
      </Flex>
    </Pressable>
  );
}
