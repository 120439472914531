import { RouteProp, useRoute } from '@react-navigation/native';
import {
  AspectRatio,
  Box,
  Image,
  ScrollView,
  Text,
  VStack,
  ZStack,
} from 'native-base';
import dayjs from 'dayjs';

import { RootStackParamList } from 'src/navigator';
import { useEffect, useState } from 'react';
import { Loading } from 'src/components/loading';
import { ShareFooter } from 'src/components/share-footer';

export interface Evento {
  title: string;
  image_url: string;
  fecha?: string;
  contenido: any;
  link: string;
}

export function EventoDetailScreen() {
  const [evento, setEvento] = useState<Evento>();

  ///Definir tipo.
  const { params }: any = useRoute<RouteProp<RootStackParamList>>();

  useEffect(() => {
    const getNew = async (url: any) => {
      const res: any = await fetch(url);
      const data = await res.json();
      setEvento({
        title: data.title,
        image_url: data.image_url,
        contenido: data.content,
        fecha: data.fecha,
        link: data.link_web,
      });
    };
    if (params.evento) {
      setEvento(params.evento);
    } else {
      getNew(params.link);
    }
  }, [params]);
  
  return evento ? (
    <ScrollView>
      <Box w="full">
        <AspectRatio ratio={1} width="full">
          <ZStack>
            <AspectRatio ratio={1} width="full">
              <Image
                resizeMode="cover"
                source={{ uri: evento.image_url }}
                alt={evento.title}
                blurRadius={10}
                overflow={'hidden'}
              />
            </AspectRatio>
            <AspectRatio ratio={1} width="full">
              <Image
                resizeMode="contain"
                source={{ uri: evento.image_url }}
                alt={evento.title}
              />
            </AspectRatio>
          </ZStack>
        </AspectRatio>
        <Box p="4">
          <VStack>
            <Text fontSize="md">
              {dayjs(evento.fecha).format('dddd, DD [de] MMMM [de] YYYY, H:mm')}
            </Text>
            <Text bold fontSize="xl" my="1">
              {evento.title}
            </Text>
          </VStack>
          <div dangerouslySetInnerHTML={{ __html: evento.contenido }} />

          <ShareFooter link={evento.link} />
        </Box>
      </Box>
    </ScrollView>
  ) : (
    <Loading />
  );
}
