import { Center, Flex, Heading, Pressable, Text } from "native-base";

import { Platform } from "react-native";

export const Problems = ({ handleChange, problemas }: any) => {
    return (
        <Pressable
            onPress={handleChange}
            w={{ base: "full", lg: "380px", xl: "400px" }}
            h={{ base: "130px", lg: "160px", xl: "160px" }}
            borderRadius={16}
            borderWidth={{ base: 0, lg: "none", xl: "none" }}
            borderColor="#dae1e6"
            bg={"#fff"}
            rounded="md"
            my={Platform.OS !== "web" ? "2" : "3"}
            mx={{ base: 0, md: "5", lg: "5", xl: "5" }}
            px="1"
            shadow={Platform.OS !== "web" ? 1 : 5}
            justifyContent="center"
            alignContent="center"
        >
            {Platform.OS !== "web" ? (
                <Flex direction="column">
                    <Heading
                        size="md"
                        textAlign={{ base: "left", lg: "start", xl: "start" }}
                        color="#191D23"
                        ml="10px"
                    >
                        {problemas.asunto}
                    </Heading>

                    <Text
                        fontSize="sm"
                        textAlign={{ base: "left", lg: "start", xl: "start" }}
                        fontWeight="normal"
                        ml="10px"
                    >
                        {problemas.descripcion}
                    </Text>

                </Flex>
            ) : (
                <Flex direction="column">
                    <Center w="100%" p="2">
                        <Heading
                            size="md"
                            textAlign={{ base: "center", lg: "start", xl: "start" }}
                            color="#004c35"
                        >
                            {problemas.asunto}
                        </Heading>
                        <Text
                            fontSize="sm"
                            textAlign={{ base: "center", lg: "start", xl: "start" }}
                            fontWeight="semibold"
                            m="5px"
                        >
                            {problemas.descripcion}
                        </Text>
                    </Center>
                </Flex>
            )}
        </Pressable>
    )
}