import {
  Flex,
  Heading,
  Image,
  Pressable,
  Stack,
  Text
} from "native-base";
import React from "react";
import { Platform } from "react-native";

export const ProblemaItem = ({
  tipoProblema,
  onPress,
  imagen,
}: {
  tipoProblema: any;
  onPress: () => void;
  imagen: any;
}) => {

  return (
    <Pressable
      onPress={onPress}
      w={Platform.OS !== "web" ? "full" : 300}
      mt={Platform.OS !== "web" ? "2" : "10"}
      backgroundColor={"white"}
      m={Platform.OS == "web" ? "1": "0"}
      p={{ base: "2", lg: "2", xl: "2" }}
      borderRadius={Platform.OS !== "web" ? "md" : 16}
      shadow={Platform.OS !== "web" ? 1 : 8}
      opacity={1}
    >
      {Platform.OS !== "web" ? (
        <Stack
          direction="row"
          w="100%"
          alignItems="center"
        >
          <Image
            source={imagen}
            h={"100"}
            w={"1/4"}
            alignSelf="center"
            alt={tipoProblema.descripcion}
            resizeMode="contain"
          />
          <Heading
            textAlign="center"
            ml={5}
            size="md"
            fontWeight="semibold"
            color="#191D23"
          >
            {tipoProblema.descripcion}
          </Heading>
        </Stack>
      ) : (
        <Stack
          py="2"
          direction="column"
          w="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Heading
            textAlign="center"
            size="md"
            fontWeight="bold"
            color="#004c35"
          >
            {tipoProblema.descripcion}
          </Heading>
          <Flex w="75%" mt="3" direction="column" justifyContent="space-around" alignContent="center">
            <Image
              source={imagen}
              h={100}
              w={100}
              alignSelf="center"
              alt={tipoProblema.descripcion}
            />
            <Text
              textAlign="center"
              mt="2"
              fontWeight="bold"
              color="#004c35"
            >
              {tipoProblema.detalle}
            </Text>
          </Flex>
        </Stack>
      )}
    </Pressable>
  );
};