import { useServicios } from "src/features/servicios/queries/use-servicios";
import { Center, ScrollView } from "native-base";
import { Servicio } from "src/features/servicios/components/servicio";
import { Loading } from "src/components/loading";
import React from "react";

export function ServicesList() {
  const { data: servicios, isLoading } = useServicios();

  return  (
    <ScrollView mb={16}>
      {isLoading ? (
          <Loading />
      ):(
        servicios?.map((servicio: any) => (
          <Servicio key={servicio.id} servicio={servicio} />
        ))
      )}
    </ScrollView>
  )
}
