import { Center, Text, Pressable, Flex, Stack } from "native-base";
import { ContactItem } from "../screens/contacto.screen";

export function CardContact({ title, contactInfo, justify }: any) {
  return (
    <Stack w="100%">
      <Text
        textAlign="center"
        mb="1"
        ml={"30px"}
        fontSize={26}
        fontWeight="700"
        color="#004c35"
        alignSelf={{ base: "center", xl: "start", "2xl": "start" }}
      >
        {title}
      </Text>
      <Center
        h={{ base: 350, xl: 200, "2xl": 200 }}
        w={{
          base: 310,
          sm: 310,
          md: 400,
          lg: "100%",
          xl: "100%",
          "2xl": "100%",
        }}
        bg="white"
        rounded="md"
        shadow={8}
        m={6}
        p="4"
        alignSelf="center"
      >
        <Flex
          flexDir={{ base: "column", xl: "row", "2xl": "row" }}
          justifyContent={justify}
          mb={30}
          w="100%"
        >
          {contactInfo.map((contact: ContactItem, index: number) => (
            <Pressable
              key={index}
              onPress={contact.url}
              mt="3"
              w={{ base: "100%", xl: "150px", "2xl": "150px" }}
              py="2"
              pl="3"
              justifyContent="center"
            >
              <Text mr="1" pt="1" alignSelf="center">
                {contact.icon}
              </Text>
              <Text
                fontSize={16}
                fontWeight="semibold"
                color="#004c35"
                alignSelf="center"
                textAlign="center"
              >
                {contact.name}
              </Text>
            </Pressable>
          ))}
        </Flex>
      </Center>
    </Stack>
  );
}
