import { useMutation } from "@tanstack/react-query";

async function login(user: any) {
  const res = await fetch(`${process.env.EXPO_PUBLIC_API_URL}/v1/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  const data = await res.json();

  if (res.status !== 200) {
    throw data;
  }

  return data;
}

export function useLogin() {
  return useMutation(login);
}
