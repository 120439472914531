import { RouteProp, useRoute } from '@react-navigation/native';
import {
  AspectRatio,
  Box,
  Center,
  Image,
  ScrollView,
  Text,
  VStack,
  ZStack,
} from 'native-base';
import dayjs from 'dayjs';
import { RootStackParamList } from 'src/navigator';
import { useEffect, useState } from 'react';
import { Loading } from 'src/components/loading';
import { ShareFooter } from 'src/components/share-footer';

export type Noticia = {
  title: string;
  image_url: string;
  body_value: any;
  fecha?: string;
  link_web: string;
};

export function NoticiaDetailScreen() {
  const [noticia, setNoticia] = useState<Noticia>();
  ///Definir tipo.
  const { params }: any = useRoute<RouteProp<RootStackParamList>>();

  useEffect(() => {
    const getNew = async (url: any) => {
      const res: any = await fetch(url);
      const data = await res.json();
      setNoticia({
        title: data.title,
        image_url: data.image_url,
        body_value: data.content,
        link_web: data.link_web,
      });
    };
    if (params.noticia) {
      setNoticia(params.noticia);
    } else {
      getNew(params.link);
    }
  }, [params]);

  return noticia ? (
    <ScrollView>
      <Box w="full" h="full">
        <AspectRatio ratio={16 / 9} width="full">
          <ZStack>
            <AspectRatio ratio={16 / 9} width="full">
              <Image
                resizeMode="cover"
                source={{ uri: noticia.image_url }}
                alt={noticia.title}
                blurRadius={10}
                overflow={'hidden'}
              />
            </AspectRatio>
            <AspectRatio ratio={16 / 9} width="full">
              <Image
                resizeMode="contain"
                source={{ uri: noticia.image_url }}
                alt={noticia.title}
              />
            </AspectRatio>
          </ZStack>
        </AspectRatio>
        <Box p="4">
          <VStack>
            {noticia?.fecha ? (
              <Text fontSize="md">
                {dayjs(noticia.fecha).format(
                  'dddd, DD [de] MMMM [de] YYYY, H:mm'
                )}
              </Text>
            ) : (
              <></>
            )}
            <Text bold fontSize="xl" my="1">
              {noticia.title}
            </Text>
          </VStack>
          <VStack flex={1}>
            <div dangerouslySetInnerHTML={{ __html: noticia.body_value }} />

            <ShareFooter link={noticia.link_web} />
          </VStack>
        </Box>
      </Box>
    </ScrollView>
  ) : (
    <Center h="100%">
      <Loading />
    </Center>
  );
}
