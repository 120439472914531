import {
  DrawerContentScrollView,
  DrawerContentComponentProps,
  DrawerItem,
} from '@react-navigation/drawer';
import { StyleSheet, Image, SafeAreaView, View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Octicons,  MaterialIcons } from '@expo/vector-icons';
import { RootStackParamList } from '../navigator';
import { StackNavigationProp } from '@react-navigation/stack';

export function AnonymousDrawer(props: DrawerContentComponentProps) {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const handleItemPress = (screen: any) => () => {
    navigation.navigate(screen);
  };

  return (
    <DrawerContentScrollView {...props}>
      <SafeAreaView>
        <View style={styles.header}>
          <Image
            source={require('../../assets/logo.png')}
            style={styles.image}
          />
        </View>
        <DrawerItem
          label="Inicio"
          labelStyle={{
            color: '#5299c1',
            fontSize: 16,
          }}
          icon={() => <MaterialIcons name="home" size={25} color="#5299c1" />}
          onPress={handleItemPress('home')}
          style={styles.option}
        />

        <DrawerItem
          label="Ingresar"
          labelStyle={{
            color: '#5299c1',
            fontSize: 16,
          }}
          icon={() => <Octicons name="sign-in" size={29} color="#c75786"/>}
          onPress={handleItemPress('login')}
          style={styles.option}
        />

        <View style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 10,
          marginTop: 20
        }}>
          <Image
            style={{
              height: 30,
              width: 30,
              objectFit: 'contain'
            }}
            source={require('../../assets/icons/muni-icon.jpg')}
          />
          <Text style={{
            fontSize: 15,
            marginLeft: 30
          }}>Ver {process.env.REACT_APP_VERSION}</Text>
        </View>
      </SafeAreaView>
    </DrawerContentScrollView>
  );
}

const styles = StyleSheet.create({
  header: {
    height: 65,
    backgroundColor: '#F6FFF8',
    paddingVertical: 5,
  },
  image: {
    flex: 1,
    width: '100%',
    resizeMode: 'contain',
  },
  option: {
    borderBottomWidth: 0.5,
    borderBottomColor: '#F6FFF8',
  },
});
