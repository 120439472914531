import { useQuery } from '@tanstack/react-query';

function getReclamos(token: string | null) {
  return async () => {
    const res = await fetch(`${process.env.EXPO_PUBLIC_API_URL}/v1/reclamo`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();

    return data;
  };
}

export function useReclamos(token: string | null) {
  return useQuery(['reclamos', token], getReclamos(token), {
    enabled: !!token,
  });
}
