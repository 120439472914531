import {
  Box,
  ScrollView,
  View,
  Input,
  FormControl,
  Button,
  Icon,
  useMediaQuery,
  Modal,
  useDisclose,
} from "native-base";
import { Entypo } from "@expo/vector-icons";
import { Image, StyleSheet } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useResetPassword } from "src/features/reset-password/queries/use-reset-password";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "src/navigator";
import React from "react";
import { FooterWeb } from "src/components/footer-web";
import { FooterWebMobile } from "src/components/footer-web-mobile";
import { ArrowBack } from "src/components/arrow-back";
import { Loading } from "src/components/loading";
import { ErrorModel } from "src/components/error-modal";
import { SuccessModel } from "src/components/success-modal";
import { HeaderWeb } from "src/components/header-web";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Email no válido.")
    .required("Este campo es obligatorio."),
});

export function ResetPasswordScreen() {
  const [showModal, setShowModal] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { mutateAsync } = useResetPassword();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclose();

  const onSubmit = async (data: any) => {
    try {
      onOpen();
      const response = await mutateAsync(data);
      setMessage(response.message);
      if (!response.code) {
        navigation.push("home");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [isSmallScreen] = useMediaQuery({
    minWidth: 1024,
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        {isSubmitting ? (
          <Loading />
        ) : (
          <Modal.Content shadow={0} bg="contrastThreshold">
            <Modal.CloseButton
              mr="2"
              mt="2"
              _hover={{
                bg: "contrastThreshold",
              }}
            />
            {Boolean(message !== "El email ha sido enviado") && (
              <Modal.Body>
                <ErrorModel
                  errorTitle="Error al Ingresar"
                  errorMessage={message}
                />
              </Modal.Body>
            )}
            {Boolean(message === "El email ha sido enviado") && (
              <Modal.Body>
                <SuccessModel
                  successTitle="Actualizacion Exitosa!"
                  successMessage={message}
                />
              </Modal.Body>
            )}
          </Modal.Content>
        )}
      </Modal>
      <HeaderWeb />
      {isSmallScreen ? (
        <ArrowBack route="login" />
      ) : (
        <Box height="12%" w="100%">
          <ArrowBack route="login" />
        </Box>
      )}
      <Box w="100%" h="81%">
          <Box
            justifyContent="center"
            alignItems="center"
            flex={4}
            mt="20"
            px="3"
          >
            <View alignSelf="center" w="50%" flex={1}>
              <Image
                source={require("../../../../assets/mcc_logo.png")}
                resizeMode="contain"
                style={styles.logo}
              />
            </View>
            <ScrollView>
              <FormControl mt="12" mb="3" isInvalid={Boolean(errors.email)}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      InputLeftElement={
                        <Icon
                          as={<Entypo name="mail" />}
                          size={5}
                          mx="2"
                          color="#07888f"
                        />
                      }
                      placeholder="Email"
                      onChangeText={onChange}
                      autoCapitalize="none"
                      value={value}
                      onKeyPress={({ nativeEvent }) => {
                        if (nativeEvent.key === "Enter") {
                          handleSubmit(onSubmit)();
                        }
                      }}
                    />
                  )}
                  name="email"
                  defaultValue=""
                />
                <FormControl.ErrorMessage>
                  {errors.email?.message}
                </FormControl.ErrorMessage>
              </FormControl>
              <Button
                width="xs"
                _hover={{
                  bgColor: "#25B3D5",
                }}
                color="blue"
                onPress={handleSubmit(onSubmit)}
                isLoading={isSubmitting}
                isLoadingText="Recuperando Contraseña"
              >
                Recuperar Contraseña
              </Button>
            </ScrollView>
          </Box>
      </Box>
      {isSmallScreen ? <FooterWeb /> : <FooterWebMobile />}
    </>
  );
}

const styles = StyleSheet.create({
  fondo: {
    width: "100%",
    height: "100%",
  },
  logo: {
    resizeMode: "contain",
    width: "100%",
    height: "100%",
  },
});
