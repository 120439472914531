import { useMutation } from '@tanstack/react-query';

async function resetPassword(email: any) {
  const response = await fetch(
    `${process.env.EXPO_PUBLIC_API_URL}/v1/sendLink`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(email),
    }
  );
  const data = await response.json();
  if (data?.error) {
    throw data?.error;
  }
  return data;
}

export function useResetPassword() {
  return useMutation(resetPassword);
}
