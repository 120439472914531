import GoogleMapReact from 'google-map-react';
import { View } from 'native-base';

export const MapDetailWeb = ({ coordinates }: any) => {
  const coordenadas = {
    lat: Number(coordinates.lat),
    lng: Number(coordinates.lng),
  };

  return (
    <View
      w="100%"
      h={{ base: "300px", lg: "200px", xl: "250px", "2xl": "250px" }}
      borderRadius="16"
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCqnPScpFdHfNvkFU88yTwuTfFUBOc2ozU' }}
        defaultCenter={coordenadas}
        defaultZoom={15}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }: any) => {
          map.setCenter(coordenadas);
          map.panTo(coordenadas);

          new maps.Marker({
            position: coordenadas,
            map,
          });
        }}
        draggable={false}
      />
    </View>
  );
};
