import { Box, Stack, Text } from "native-base";
import { Platform } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "src/navigator";
import { ArrowBack } from "src/components/arrow-back";
import { ScreensHeader } from "src/components/screens-header";
import { useConfig } from "src/features/reclamos/queries/use-config";
import { useAppContext } from "src/contexts/app-context";
import { LayoutAuthenticated } from "src/components/layout-authenticated";
import { Problems } from "../components/Problems";

interface ProblemasScreenProps {
  route: {
    params: {
      id_grupo_problema: string;
    };
  };
}

export const ProblemasScreen = ({ route }: ProblemasScreenProps) => {
  const { token } = useAppContext();
  const { data: config } = useConfig(token);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const { id_grupo_problema } = route.params;
  const problema = config?.problemas?.find(
    (grupoProblema: any) =>
      grupoProblema.id_grupo_problema === Number(id_grupo_problema)
  );

  if (!problema) {
    return null;
  }

  function handleProblemaChanged(id_problema: any) {
    navigation.navigate("reclamos", { id_problema, id_grupo_problema });
  }

  return (
    <LayoutAuthenticated>
      {Platform.OS !== "web" ? (
        <Stack bg="white">
          <Text color="#004C35" fontSize="lg" ml={4} maxWidth={300}>
            Seleccioná el problema
          </Text>
        </Stack>
      ) : (
        <>
          <ScreensHeader
            title="Nuevo Reclamo"
            description="Seleccioná el problema"
          />
          <ArrowBack route="tipo-problema" />
        </>
      )}
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        mt={Platform.OS !== "web" ? "0" : "20px"}
        mb={Platform.OS === "ios" ? '40' : '16'}
        pt={"3"}
        backgroundColor={Platform.OS !== "web" ? "white" : "transparent"}
        mx={2}
      >
        {problema?.problemas.map((problema: any, index: number) => {
          return (
            <Problems
              key={index}
              problemas={problema}
              handleChange={() => handleProblemaChanged(problema.id_problema)}
            />
          );
        })}
      </Box>
    </LayoutAuthenticated>
  );
};
