import { useForm, Controller } from "react-hook-form";
import { StyleSheet, View } from "react-native";
import {
  Box,
  Text,
  Input,
  FormControl,
  Button,
  Icon,
  useMediaQuery,
  Modal,
  useDisclose,
  Flex,
  Center,
} from "native-base";
import { Entypo } from "@expo/vector-icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useLogin } from "../queries/use-login";
import { useAppContext } from "src/contexts/app-context";
import { RootStackParamList } from "src/navigator";
import React, { useEffect } from "react";
import { FooterWeb } from "src/components/footer-web";
import { FooterWebMobile } from "src/components/footer-web-mobile";
import { Loading } from "src/components/loading";
import { ErrorModel } from "src/components/error-modal";
import { TouchableOpacity } from "react-native-gesture-handler";
import { ArrowBack } from "src/components/arrow-back";
import { HeaderWeb } from "src/components/header-web";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export function LoginScreen() {
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(true);
  const { mutateAsync } = useLogin();
  const { login, token } = useAppContext();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      fcm: 1,
      telefono: 1,
    },
    resolver: yupResolver(schema),
  });
  const [isSmallScreen] = useMediaQuery({
    minWidth: 1024,
  });

  const { isOpen, onOpen, onClose } = useDisclose();

  async function onSubmit(data: any) {
    try {
      onOpen();
      const response = await mutateAsync(data);
      login(response);
      navigation.navigate("home");
    } catch (_error: any) {
      setShowError(true);
      setErrorMessage(_error.error);
    }
  }

  const autoCloseModal = () => {
    onOpen();
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        {isSubmitting ? (
          <Loading />
        ) : (
          showError && (
            <Modal.Content shadow={0} bg="white">
              <Modal.CloseButton
                mr="1"
                mt="1"
                _hover={{
                  bg: "contrastThreshold",
                }}
              />
              <Modal.Body>
                {showError && (
                  <ErrorModel
                    errorTitle="Error al Ingresar"
                    errorMessage={errorMessage}
                  />
                )}
              </Modal.Body>
              <Modal.Footer justifyContent="center" bg="white" p={4} width="100%">
                <TouchableOpacity
                  onPress={() => {
                    autoCloseModal();
                  }}
                >
                  <Button
                    variant="link"
                    onPress={() => navigation.navigate("reset-password")}
                    color="coolGray.600"
                    fontWeight="bold"
                  >
                    ¿Recuperar su contraseña?
                  </Button>
                </TouchableOpacity>
              </Modal.Footer>
            </Modal.Content>
          ))}
      </Modal>
      <HeaderWeb />
      {isSmallScreen ? (
        <ArrowBack route="home" />
      ) : (
        <Box height="10" w="100%">
          <ArrowBack route="home" />
        </Box>
      )}
      <Center mt="5" p="1" mb="4">
        <Box
          borderWidth={2}
          borderColor="#0891b2"
          padding={4}
          flex={1}
          flexDirection="row"
          shadow="5"
        >
          <Entypo
            name="mobile"
            size={isSmallScreen ? 50 : 40}
            color={"#0891b2"}
          />
          <Text
            fontSize={isSmallScreen ? "md" : "xs"}
            fontWeight="semibold"
            p={isSmallScreen ? "4" : null}
          >
            Si ya tenías la App de la muni en tu celular, podés ingresar con la
            mísma cuenta.
          </Text>
        </Box>
      </Center>
      <Box style={styles.container} w="100%" paddingBottom={10}>
        <Box style={styles.content}>
          <View style={styles.formContainer}>
            <FormControl isInvalid={Boolean(errors.email)}>
              <FormControl.Label>Email</FormControl.Label>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    px="2"
                    InputLeftElement={
                      <Icon
                        as={<Entypo name="mail" />}
                        size={5}
                        mx="2"
                        color="#07888f"
                      />
                    }
                    placeholder="Email"
                    autoCapitalize="none"
                    onChangeText={onChange}
                    onSubmitEditing={handleSubmit(onSubmit)}
                    value={value}
                  />
                )}
                name="email"
                defaultValue=""
              />
              <FormControl.ErrorMessage>
                Email invalido
              </FormControl.ErrorMessage>
            </FormControl>
            <Button
              variant="link"
              color="white"
              alignSelf="flex-end"
              onPress={() => navigation.navigate("reset-password")}
            >
              <Text fontWeight="semibold" fontSize="xs" color="#07888f">
                ¿Ha olvidado su contraseña?
              </Text>
            </Button>
            <FormControl isInvalid={Boolean(errors.password)}>
              <FormControl.Label>Contraseña</FormControl.Label>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    px="2"
                    placeholder="Contraseña"
                    InputRightElement={
                      <Icon
                        onPress={() => setShowPassword(!showPassword)}
                        as={
                          <Entypo
                            name={showPassword ? "eye-with-line" : "eye"}
                          />
                        }
                        size={5}
                        mx="2"
                        color="#07888f"
                      />
                    }
                    secureTextEntry={showPassword ? true : false}
                    onChangeText={onChange}
                    onSubmitEditing={handleSubmit(onSubmit)}
                    value={value}
                  />
                )}
                name="password"
                defaultValue=""
              />
              <FormControl.ErrorMessage>
                Contraseña Invalida
              </FormControl.ErrorMessage>
            </FormControl>
            <Flex
              flex={1}
              flexDirection={isSmallScreen ? "column" : "row"}
              marginRight={isSmallScreen ? null : "6"}
            >
              <Button
                colorScheme="secondary"
                color="white"
                onPress={handleSubmit(onSubmit)}
                isLoading={isSubmitting}
                isLoadingText="Ingresando"
                width={isSmallScreen ? "md" : "50%"}
                marginTop={isSmallScreen ? "5" : "7"}
              >
                <Text style={styles.textButton}>Ingresar</Text>
              </Button>
              <Button
                variant="link"
                onPress={() => navigation.navigate("register")}
              >
                {isSmallScreen ? (
                  <Text color="#C75786" fontWeight="semibold" fontSize="md">
                    ¿Primera Vez?
                  </Text>
                ) : null}
              </Button>
              <Button
                _hover={{
                  bgColor: "#25B3D5",
                }}
                color="white"
                onPress={() => navigation.navigate("register")}
                width={isSmallScreen ? "md" : "50%"}
                marginTop={isSmallScreen ? null : "7"}
              >
                <Text style={styles.textButton}>Registrarme</Text>
              </Button>
            </Flex>
          </View>
        </Box>
      </Box>
      {isSmallScreen ? <FooterWeb /> : <FooterWebMobile />}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
    flex: 3,
  },
  logoContainer: {
    marginVertical: 20,
    width: "50%",
    alignSelf: "center",
    flex: 1,
  },
  logo: {
    resizeMode: "contain",
    width: "100%",
    height: "100%",
  },
  fondo: {
    width: "100%",
    height: "100%",
  },
  formContainer: {
    flex: 1,
  },
  btnIngresar: {
    marginTop: 2,
    color: "#e60000",
  },
  textButton: {
    color: "white",
  },
});
