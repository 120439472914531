import { useMutation } from '@tanstack/react-query';

export interface Reclamo {
  dni: number;
  id_problema: string;
  celular: string;
  descripcion?: string;
  otro_dato_ubicacion?: string;
  coordenadas?: any;
  images: Array<string> | [];
}

function addReclamo(token: string | null) {
  return async (reclamo: Reclamo) => {
    const {
      dni,
      id_problema,
      celular,
      descripcion,
      otro_dato_ubicacion,
      coordenadas,
      images,
    } = reclamo;

    const base64Row = images
      ?.map((base64image) =>
        base64image.includes('base64,')
          ? base64image
          : `data:image/jpg;base64,${base64image}`
      )
      .join('|');

    const param = {
      longitud: coordenadas?.lng,
      latitud: coordenadas?.lat,
      documento: dni,
      id_problema,
      celular,
      otro_dato_ubicacion,
      descripcion,
      images: images.length > 0 ? base64Row : '',
    };

    const response = await fetch(
      `${process.env.EXPO_PUBLIC_API_URL}/v1/reclamo`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(param),
      }
    );

    
    const data = await response.json();
  
    return data;
  };
}

export function useAddReclamo(token: string | null) {
  return useMutation(addReclamo(token));
}
