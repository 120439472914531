import { Button, Stack, Text } from "native-base";

export function Pagination({ currentPage, prevPage, nextPage, totalPages }: any) {
    return (
        <Stack
            mt="6"
            direction="row"
            alignSelf="center"
            justifyContent="center"
        >
            <Button
                colorScheme="emerald"
                onPress={prevPage}
                isDisabled={currentPage === 1}
                mr="3"
                w="32"
            >
                Anterior
            </Button>
            <Text
                color="emerald.700"
                fontSize={18}
                fontWeight="semibold"
                mt="2"
            >
                {currentPage} - {Math.floor(totalPages)}
            </Text>
            <Button
                colorScheme="emerald"
                onPress={nextPage}
                ml="3"
                w="32"
            >
                Siguiente
            </Button>
        </Stack>
    )
}