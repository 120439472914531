import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Fab, useMediaQuery } from "native-base";
import { Platform } from "react-native";
import { RootStackParamList } from "src/navigator";

export function ArrowBack({ route }: any) {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [isSmallScreen] = useMediaQuery({
    minWidth: 1024,
  });

  if (Platform.OS !== "web") {
    return null;
  }

  return (
    <Fab
      renderInPortal={false}
      variant="solid"
      colorScheme="pink"
      boxSize={{ base: 6, lg: "none", xl: "none" }}
      mt={1}
      shadow={2}
      right={5}
      top={{
        base: 5,
        lg: 130,
        xl: 130,
      }}
      placement="top-right"
      size="sm"
      icon={<Feather name="arrow-left" size={14} color="white" />}
      _text={{
        fontWeight: "semibold",
        fontSize: 14,
      }}
      label={isSmallScreen ? "Volver": ""}
      onPress={() => navigation.navigate(route)}
    />
  );
}
