import { VStack, Alert, Text } from "native-base";

export function ErrorModel({ errorTitle, errorMessage }: any) {
    return (
        <Alert w="96" h="96" p={8} width="100%" height="100%" shadow={8} status="error">
            <VStack space={1} flexShrink={1} w="100%" alignItems="center">
                <Alert.Icon size="md" />
                <Text
                    fontSize="md"
                    fontWeight="medium"
                    _dark={{
                        color: "coolGray.800",
                    }}
                >
                    {errorTitle}
                </Text>
                <Text textAlign="center" color="coolGray.800">
                    {errorMessage}
                </Text>
                
            </VStack>
        </Alert>
    );
}
