import { useMutation } from '@tanstack/react-query';

interface UpdateInfo {
  name: string;
  lastname: string;
  celular: number;
  domicilio: string;
}

function updateUser(token: string | null) {
  return async (userInfo: UpdateInfo) => {
    const { name, lastname, celular, domicilio } = userInfo;

    const params = {
      name,
      lastname,
      celular,
      domicilio,
    };

    const res = await fetch(`${process.env.EXPO_PUBLIC_API_URL}/v1/user_edit`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    });
    const data = await res.json();

    if (!res.ok) {
      return Promise.reject(data);
    }

    return data;
  };
}

export function useUpdateUser(token: string | null) {
  return useMutation(updateUser(token));
}
