import { ReclamosList } from '../components/reclamos-list';
import { ArrowBack } from '../../../components/arrow-back';
import { ScreensHeader } from 'src/components/screens-header';
import { LayoutAuthenticated } from 'src/components/layout-authenticated';

// Guard 
import AuthGuard from 'src/guard/Guard';

export function MisReclamosScreen() {
  return (
    <AuthGuard> 
        <LayoutAuthenticated>
            <ScreensHeader
              title="Mis reclamos"
              description="Visualiza todos tus reclamos hechos"
            />
            <ArrowBack route="home" />
            <ReclamosList />
        </LayoutAuthenticated>
    </ AuthGuard>
        
  );
}