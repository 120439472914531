import {
  GetSearchProps,
  useSearch,
} from 'src/features/busqueda/queries/use-search';
import {
  Box,
  Center,
  Divider,
  FlatList,
  Heading,
  HStack,
  Text,
  VStack,
} from 'native-base';
import { Loading } from 'src/components/loading';
import { Platform, TouchableHighlight } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from 'src/navigator';

export function SearchResultsList({
  queryParams,
}: {
  queryParams: GetSearchProps;
}) {
  const { data: searchResults, isLoading: isSearching } =
    useSearch(queryParams);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const handleOnNavigateDetails = (
    link: string,
    title: string,
    type: string
  ) => {
    switch (type) {
      case 'actividades': {
        return {
          route: 'noticia-detail',
          params: {
            link: link,
            title: title,
          },
        };
      }
      case 'agenda_cultural': {
        return {
          route: 'evento-detail',
          params: {
            link: link,
            title: title,
          },
        };
      }
      case 'servicio': {
        return {
          route: 'servicio-detail',
          params: {
            link: link,
            title: title,
          },
        };
      }
      case 'vecino': {
        return {
          route: 'vecino-detail',
          params: {
            link: link,
            title: title,
          },
        };
      }
      case 'tramite': {
        return {
          route: 'tramite-detail',
          params: {
            link: link,
            title: title,
          },
        };
      }
      default: {
        return {
          route: 'noticia-detail',
          params: {
            link: link,
            title: title,
          },
        };
      }
      case 'mis-reclamos': {
        return {
          route: 'mis-reclamos',
          params: {
            link: link,
            title: title,
          },
        };
      }
    }
  };

  const ResultsList = () => {
    return (
      <VStack>
        <Heading size={'lg'} m={3}>
          Resultados para la búsqueda de "{queryParams.queryParam}"
        </Heading>
        <FlatList
          mb={'48'}
          data={searchResults}
          renderItem={({ item }: any) => (
            <TouchableHighlight
              key={item.entity_id}
              underlayColor="transparent"
              onPress={() => {
                const { route, params } = handleOnNavigateDetails(
                  item.link,
                  item.title,
                  item.type
                );
                // @ts-ignore
                navigation.navigate(route, params);
              }}
            >
              <Box>
                <HStack alignItems="center" p="3">
                  <VStack w="70%" ml="2">
                    <Text bold fontSize="14">
                      {item.title}
                    </Text>
                    <Text fontSize="14">
                      <Entypo name="forward" size={14} />
                      {` ${item.type}`}
                    </Text>
                  </VStack>
                </HStack>
                <Divider />
              </Box>
            </TouchableHighlight>
          )}
        />
      </VStack>
    );
  };

  if (isSearching) {
    return (
      <Center h="100%">
        <Loading />
      </Center>
    );
  }

  return searchResults.length >= 1 ? (
    <ResultsList />
  ) : (
    <Center>
      <Text bold>Sin resultados u.u</Text>
    </Center>
  );
}
