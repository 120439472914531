import { Pressable, Image, Text } from "native-base";

export const NavItem = ({ image, children, navigate }: any) => (
  <Pressable
    _hover={{
      bg: "#004C351A",
    }}
    flexDirection="row"
    alignItems="center"
    p="3"
    w="100%"
    rounded={{ base: "none", lg: "3xl", xl: "3xl" }}
    borderRightRadius={0}
    onPress={navigate}
  >
    <Image src={image} alt="icon" size="5" />
    <Text color="#5299c1" ml="3" fontSize={18}>
      {children}
    </Text>
  </Pressable>
);