import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Modal,
  ScrollView,
  Text,
  Icon,
  Input,
  FormControl,
  Button,
  Pressable,
  Select,
  Center,
  Flex,
  useMediaQuery,
  useDisclose,
  useBreakpointValue,
} from "native-base";
import {
  MaterialIcons,
  Entypo,
  Foundation,
  AntDesign,
} from "@expo/vector-icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppContext } from "src/contexts/app-context";
import { useRegister } from "../queries/use-register";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "src/navigator";
import React from "react";
import { FooterWeb } from "src/components/footer-web";
import { FooterWebMobile } from "src/components/footer-web-mobile";
import { HeaderWeb } from "src/components/header-web";
import { ErrorModel } from "src/components/error-modal";
import { Loading } from "src/components/loading";
import { ArrowBack } from "src/components/arrow-back";

/// Schema for register form
const schema = yup.object().shape({
  email: yup
    .string()
    .email("Email no válido.")
    .required("Este campo es obligatorio."),
  name: yup
    .string()
    .min(2, "Muy corto! 3 o más caracteres")
    .max(50, "Muy largo!")
    .required("Este campo es obligatorio.")
    .matches(
      /(^[A-ZÁÉÍÓÚ]|[a-zñáéíóú]([a-zñáéíóú]+){2,})(\s(([a-zñáéíóú]|[A-ZÁÉÍÓÚ])+)+)*$/,
      "No debe contener números ni espacios."
    ),
  lastname: yup
    .string()
    .min(2, "Muy corto! 3 o más caracteres")
    .max(100, "Muy largo!")
    .required("Este campo es obligatorio.")
    .matches(
      /(^[A-ZÁÉÍÓÚ]|[a-zñáéíóú]([a-zñáéíóú]+){2,})(\s(([a-zñáéíóú]|[A-ZÁÉÍÓÚ])+)+)*$/,
      "No debe contener números ni espacios."
    ),
  id_sexo: yup
    .string()
    .min(1, "Este campo es obligatorio")
    .required("Este campo es obligatorio"),
  dni: yup
    .number()
    .min(100000, "Muy corto!")
    .max(199999999, "Muy largo")
    .required("Este campo es obligatorio")
    .typeError("Ingrese un número válido"),
  "cuit-cuil": yup
    .number()
    .min(100000, "Muy corto!")
    .max(99999999999, "Muy largo")
    .required("Este campo es obligatorio")
    .typeError("Ingrese un número válido"),
  celular: yup
    .number()
    .min(100000, "Telefono no válido.")
    .max(999999999999999, "Muy largo.")
    .required("Este campo es obligatorio.")
    .typeError("Ingrese un número válido"),
  password: yup
    .string()
    .min(8, "La contraseña es muy corta")
    .max(100, "Muy Larga")
    .required("Este campo es obligatorio.")
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .matches(/[A-Z]/, "Debe contener al menos una mayúscula")
    .matches(/[a-z]/, "Debe contener al menos una minúscula")
    .matches(/[0-9]/, "Debe contener al menos un número"),
  c_password: yup
    .string()
    .ensure()
    .test(
      "password-match",
      "Las contraseñas deben coincidir",
      function (repeatPassword) {
        return this.parent.password === repeatPassword;
      }
    ),
  otro_dato_dom: yup.string().required("Este campo es requerido"),
});

interface ErrorData {
  message: string;
  data: {
    [key: string]: string[];
  };
}

export function RegisterScreen() {
  const [show, setShow] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<any>(null);
  const { mutateAsync } = useRegister();
  const { register } = useAppContext();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { isOpen, onOpen, onClose } = useDisclose();
  const [isSmallScreen] = useMediaQuery({
    minWidth: 1024,
  });
  const inputPaddings = useBreakpointValue({
    base: 0,
    lg: "2",
    xl: "2",
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      lastname: "",
      id_sexo: "",
      dni: "",
      "cuit-cuil": "",
      celular: "",
      password: "",
      c_password: "",
      otro_dato_dom: "",
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  // handleSubmit function for register
  async function onSubmit(data: any) {
    try {
      onOpen();
      const response = await mutateAsync({
        ...data,
        dni: data.dni.toString(),
        "cuit-cuil": data["cuit-cuil"].toString(),
        celular: data.celular.toString(),
      });

      register(response);
      navigation.navigate("home");
    } catch (_error: any) {
      setShowError(true);
      setErrorMessage(_error);
    }
  }

  function extractErrorMessages(errorData: ErrorData): string[] {
    const errorMessages: string[] = [];

    if (errorData && errorData.data) {
      Object.keys(errorData.data).forEach((field) => {
        errorData.data[field].forEach((message) => {
          errorMessages.push(message);
        });
      });
    }

    return errorMessages;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        {isSubmitting ? (
          <Loading />
        ) : (
          <Modal.Content shadow={0} bg="contrastThreshold">
            <Modal.CloseButton
              mr="1"
              mt="1"
              _hover={{
                bg: "contrastThreshold",
              }}
            />
            {showError && (
              <Modal.Body>
                <ErrorModel
                  errorTitle="Error al Registrarse, verifique los datos!"
                  errorMessage={extractErrorMessages(errorMessage).join("\n ")}
                />
              </Modal.Body>
            )}
          </Modal.Content>
        )}
      </Modal>
      <HeaderWeb />
      {isSmallScreen ? (
        <ArrowBack route="login" />
      ) : (
        <Box height="12%" w="100%">
          <ArrowBack route="login" />
        </Box>
      )}
      <Box
        flex="1"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        w="100%"
        h="100%"
      >
        <Center px="3" mb={{ base: "40", lg: 0, xl: 0 }} w="100%">
          <ScrollView flex="1" showsVerticalScrollIndicator={false}>
            <Text
              fontSize="xl"
              textAlign="center"
              fontWeight="semibold"
              py="5"
              color="#C75786"
            >
              Registro Usuario
            </Text>
            <FormControl
              mb="2"
              mt="4"
              isRequired
              isInvalid={Boolean(errors.email)}
            >
              <FormControl.Label ml="2">Email</FormControl.Label>
              <Controller
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Input
                    InputLeftElement={
                      <Icon
                        as={<Entypo name="mail" />}
                        size={5}
                        ml="2"
                        color="#07888f"
                      />
                    }
                    placeholder="Ej. example@gmail.com"
                    autoCapitalize="none"
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    onKeyPress={({ nativeEvent }) => {
                      if (nativeEvent.key === "Enter") {
                        handleSubmit(onSubmit)();
                      }
                    }}
                  />
                )}
                name="email"
                defaultValue=""
              />
              <FormControl.ErrorMessage>
                {errors.email?.message}
              </FormControl.ErrorMessage>
            </FormControl>
            <Flex direction={isSmallScreen ? "row" : "column"} w="100%">
              <Box flex="1" pr={inputPaddings} mt="4">
                <FormControl mb="2" isRequired isInvalid={Boolean(errors.name)}>
                  <FormControl.Label ml="2">Nombre</FormControl.Label>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <Input
                        InputLeftElement={
                          <Icon
                            as={<MaterialIcons name="person" />}
                            size={5}
                            ml="2"
                            color="#07888f"
                          />
                        }
                        placeholder="Nombre"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        onKeyPress={({ nativeEvent }) => {
                          if (nativeEvent.key === "Enter") {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                    name="name"
                    defaultValue=""
                  />
                  <FormControl.HelperText>
                    {errors.name?.message}
                  </FormControl.HelperText>
                </FormControl>
              </Box>
              <Box flex="1" pl={inputPaddings} mt="4">
                <FormControl
                  mb="2"
                  isRequired
                  isInvalid={Boolean(errors.lastname)}
                >
                  <FormControl.Label ml="2">Apellido</FormControl.Label>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <Input
                        InputLeftElement={
                          <Icon
                            as={<MaterialIcons name="person" />}
                            size={5}
                            ml="2"
                            color="#07888f"
                          />
                        }
                        placeholder="Apellido"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        onKeyPress={({ nativeEvent }) => {
                          if (nativeEvent.key === "Enter") {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                    name="lastname"
                    defaultValue=""
                  />
                  <FormControl.HelperText>
                    {errors.lastname?.message}
                  </FormControl.HelperText>
                </FormControl>
              </Box>
              <Box flex="1" pl={inputPaddings} mt="4">
                <FormControl isRequired isInvalid={Boolean(errors.dni)}>
                  <FormControl.Label ml="2">DNI</FormControl.Label>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <Input
                        InputLeftElement={
                          <Icon
                            as={<AntDesign name="idcard" />}
                            size={5}
                            ml="2"
                            color="#07888f"
                          />
                        }
                        placeholder="Ej. 43274312"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        onKeyPress={({ nativeEvent }) => {
                          if (nativeEvent.key === "Enter") {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                    name="dni"
                  />
                  <FormControl.HelperText>
                    {errors.dni?.message}
                  </FormControl.HelperText>
                </FormControl>
              </Box>
            </Flex>
            <Box flex="1" mt="4">
              <FormControl
                mb="2"
                isRequired
                isInvalid={Boolean(errors.otro_dato_dom)}
              >
                <FormControl.Label ml="2">Domicilio</FormControl.Label>
                <Controller
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <Input
                      InputLeftElement={
                        <Icon
                          as={<Foundation name="marker" />}
                          size={5}
                          ml="2"
                          color="#07888f"
                        />
                      }
                      placeholder="Domicilio"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      onKeyPress={({ nativeEvent }) => {
                        if (nativeEvent.key === "Enter") {
                          handleSubmit(onSubmit)();
                        }
                      }}
                    />
                  )}
                  name="otro_dato_dom"
                  defaultValue=""
                />
                <FormControl.HelperText>
                  {errors.otro_dato_dom?.message}
                </FormControl.HelperText>
              </FormControl>
            </Box>
            <Flex direction={isSmallScreen ? "row" : "column"} w="100%">
              <Box flex="1" pr={inputPaddings} mt="4">
                <FormControl
                  mb="2"
                  isRequired
                  isInvalid={Boolean(errors.lastname)}
                >
                  <FormControl.Label ml="2">Sexo</FormControl.Label>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        placeholder="Seleccione el tipo de sexo"
                        selectedValue={value}
                        onValueChange={(newValue) => {
                          onChange(newValue);
                        }}
                      >
                        <Select.Item label="Masculino" value="1" />
                        <Select.Item label="Femenino" value="2" />
                        <Select.Item label="No Binario" value="3" />
                      </Select>
                    )}
                    name="id_sexo"
                    defaultValue=""
                  />
                  <FormControl.HelperText>
                    {errors.id_sexo?.message}
                  </FormControl.HelperText>
                </FormControl>
              </Box>
              <Box flex="1" pr={inputPaddings} mt="4">
                <FormControl isRequired isInvalid={Boolean(errors.dni)}>
                  <FormControl.Label ml="2">CUIT/CUIL</FormControl.Label>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <Input
                        InputLeftElement={
                          <Icon
                            as={<AntDesign name="idcard" />}
                            size={5}
                            ml="2"
                            color="#07888f"
                          />
                        }
                        placeholder="Ej. 20432743123"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        onKeyPress={({ nativeEvent }) => {
                          if (nativeEvent.key === "Enter") {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                    name="cuit-cuil"
                  />
                  <FormControl.HelperText>
                    {errors["cuit-cuil"]?.message}
                  </FormControl.HelperText>
                </FormControl>
              </Box>
              <Box flex="1" pl={inputPaddings} mt="4">
                <FormControl
                  mb="2"
                  isRequired
                  isInvalid={Boolean(errors.celular)}
                >
                  <FormControl.Label ml="2">Celular</FormControl.Label>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <Input
                        InputLeftElement={
                          <Icon
                            as={<Foundation name="telephone" />}
                            size={5}
                            ml="2"
                            color="#07888f"
                          />
                        }
                        placeholder="Ej. 3794216432"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        onKeyPress={({ nativeEvent }) => {
                          if (nativeEvent.key === "Enter") {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                    name="celular"
                  />
                  <FormControl.ErrorMessage>
                    {errors.celular?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              </Box>
            </Flex>
            <Flex direction="row">
              <FormControl
                isRequired
                mt="2"
                mr="2"
                flex="1"
                isInvalid={Boolean(errors.password)}
              >
                <FormControl.Label ml="2">Contraseña</FormControl.Label>
                <Controller
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <Input
                      InputLeftElement={
                        <Pressable onPress={() => setShow(!show)}>
                          <Icon
                            as={
                              <MaterialIcons
                                name={show ? "visibility" : "visibility-off"}
                              />
                            }
                            size={5}
                            ml="2"
                            color="#07888f"
                          />
                        </Pressable>
                      }
                      placeholder="**********"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      secureTextEntry={show ? false : true}
                      onKeyPress={({ nativeEvent }) => {
                        if (nativeEvent.key === "Enter") {
                          handleSubmit(onSubmit)();
                        }
                      }}
                    />
                  )}
                  name="password"
                  defaultValue=""
                />
                <FormControl.HelperText textAlign="start">
                  {errors.password
                    ? errors.password.message
                    : "Debe contener al menos una mayúscula, al menos una minúscula, al menos un número y longitud mínima de 8 caracteres."}
                </FormControl.HelperText>
              </FormControl>
              <FormControl
                isRequired
                mt="2"
                flex="1"
                isInvalid={Boolean(errors.c_password)}
              >
                <FormControl.Label ml="2">Repetir Contraseña</FormControl.Label>
                <Controller
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <Input
                      InputLeftElement={
                        <Pressable onPress={() => setShow(!show)}>
                          <Icon
                            as={
                              <MaterialIcons
                                name={show ? "visibility" : "visibility-off"}
                              />
                            }
                            size={5}
                            ml="2"
                            color="#07888f"
                          />
                        </Pressable>
                      }
                      placeholder="**********"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      secureTextEntry={show ? false : true}
                      onKeyPress={({ nativeEvent }) => {
                        if (nativeEvent.key === "Enter") {
                          handleSubmit(onSubmit)();
                        }
                      }}
                    />
                  )}
                  name="c_password"
                  defaultValue=""
                />
                <FormControl.ErrorMessage>
                  {errors.c_password?.message}
                </FormControl.ErrorMessage>
              </FormControl>
            </Flex>
            <Button
              _hover={{
                bgColor: "#d85e91",
              }}
              mt="6"
              bgColor="#c75786"
              color="white"
              onPress={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
            >
              <Text color="white">Registrarme</Text>
            </Button>
          </ScrollView>
        </Center>
      </Box>
      {isSmallScreen ? <FooterWeb /> : <FooterWebMobile />}
    </>
  );
}
