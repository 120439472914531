import { Center, ScrollView } from "native-base";
import { useTramites } from "../queries/use-tramites";
import { Loading } from "src/components/loading";
import React from "react";
import { Tramites } from "./tramite";
import { getHeightFromPercentage } from "src/utils";
import { Platform } from "react-native";

export function TramiteList() {
  const { data: tramites, isLoading } = useTramites();

  return !isLoading ? (
    <ScrollView bg="white" mb={Platform.OS === "android" ? 12 : 16} height="auto">
      {tramites?.map((tramites: any) => (
        <Tramites key={tramites.id} tramite={tramites} />
      ))}
    </ScrollView>
  ) : (
    <Loading />
  );
}