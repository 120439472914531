import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Share } from 'react-native';
import { Box, HStack, ScrollView, Text } from 'native-base';
import { useRoute, RouteProp } from '@react-navigation/native';
import FontAwesome from '@expo/vector-icons/FontAwesome';

import { RootStackParamList } from '../../../navigator';
import { Loading } from '../../../components/loading';

const onShare = async (link: any) => {
  try {
    const result = await Share.share({
      message: link,
    });

    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        console.log('1');
        // shared with activity type of result.activityType
      } else {
        console.log('2');
        // shared
      }
    } else if (result.action === Share.dismissedAction) {
      console.log('3');
      // dismissed
    }
  } catch (error: any) {
    alert(error.message);
  }
};

export function VecinoDetailScreen() {
  const [vecino, setVecino] = useState<any>();
  const [link, setLink] = useState<any>('');
  const [expanded, setExpanded] = useState<any>([]);
  const { params }: any = useRoute<RouteProp<RootStackParamList>>();

  const getMainVecinosDetail = async () => {
    const res = await fetch(params?.link);
    const data = await res.json();

    setVecino(data);
    setLink(`${data.title} ${data.link}`);
  };

  useEffect(() => {
    setExpanded(false);
    getMainVecinosDetail();
  }, []);

  if (!vecino) {
    return <Loading />;
  }

  return (
    <ScrollView>
      <Box w="full" backgroundColor="white">
        <Box>
          <Text fontSize="xl" bold>
            {vecino.title}
          </Text>
          <div dangerouslySetInnerHTML={{ __html: vecino.content }} />
        </Box>
        <Box mb="8" px="2" w="full">
          <HStack flex={1} alignItems="center" justifyContent="space-between">
            <FontAwesome
              name="share-alt"
              style={styles.footerIcon}
              onPress={() => onShare(link)}
            />
            <FontAwesome
              name="whatsapp"
              style={styles.footerIcon}
              onPress={() => onShare(link)}
            />
            <FontAwesome
              name="twitter"
              style={styles.footerIcon}
              onPress={() => onShare(link)}
            />
            <FontAwesome
              name="facebook-square"
              style={styles.footerIcon}
              onPress={() => onShare(link)}
            />
          </HStack>
        </Box>
      </Box>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  footerIcon: {
    fontSize: 22,
    color: '#0087ff',
  },
});
