import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface AppContextValue {
  user: any | null;
  token: string | null;
  login: (data: any) => void;
  register: (data: any) => void;
  logout: () => void;
  autoLogout: () => void;
  editUser(newUserData: any): Promise<void>;
}
interface AppContextProvider {
  children: ReactNode;
}

export const AppContext = createContext<AppContextValue | null>(null);

export function useAppContext() {
  const appContext = useContext(AppContext);

  if (appContext === null) {
    throw new Error("useAppContext was used outside of its Provider");
  }

  return appContext;
}

export function AppContextProvider({ children }: AppContextProvider) {
  const [token, setToken] = useState<AppContextValue["token"]>(null);
  const [user, setUser] = useState<AppContextValue["user"]>(null);

  async function login(data: any) {
    await AsyncStorage.setItem("@token", data?.data?.token);
    await AsyncStorage.setItem("@user", JSON.stringify(data?.data?.user));

    setToken(data?.data?.token);
    setUser(data?.data?.user);
  }

  async function register(data: any) {
    await AsyncStorage.setItem("@token", data?.data?.token);
    await AsyncStorage.setItem("@user", JSON.stringify(data?.data?.user));

    setToken(data?.data?.token);
    setUser(data?.data?.user);
  }

  async function logout() {
    await AsyncStorage.removeItem("@token");
    await AsyncStorage.removeItem("@user");

    setToken(null);
    setUser(null);
  }

  async function editUser(newUserData: any) {
    setUser((prevUser: any) => ({ ...prevUser, ...newUserData }));
    await AsyncStorage.setItem(
      "@user",
      JSON.stringify({ ...user, ...newUserData })
    );
  }

  async function autoLogout() {
    await AsyncStorage.removeItem("@token");
    await AsyncStorage.removeItem("@user");

    setToken(null);
    setUser(null);
  }

  useEffect(() => {
    AsyncStorage.getItem("@token").then((value) => {
      if (value) {
        setToken(value);
      }
    });

    AsyncStorage.getItem("@user").then((value) => {
      if (value && value !== "undefined") {
        setUser(JSON.parse(value));
      }
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        token,
        user,
        login,
        register,
        logout,
        autoLogout,
        editUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
