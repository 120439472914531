import { Spinner } from "native-base";
import { StyleSheet, View } from "react-native";

export function Loading() {
  return (
    <View style={styles.containerLoading}>
      <Spinner size="lg"  />
    </View>
  );
}

const styles = StyleSheet.create({
  containerLoading: {
    flex: 1,
    width: "100%",
    minHeight: 500,
    justifyContent: "center",
    alignItems: "center",
  },
});
