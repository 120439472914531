import { Box, Text } from "native-base";
import { useState } from "react";
import { LatestNewsList } from "src/features/noticias/components/latest-news-list/latest-news-list";
import { SearchBar } from "src/features/busqueda/components/search-bar/search-bar";
import { GetSearchProps } from "src/features/busqueda/queries/use-search";
import { SearchResultsList } from "src/features/busqueda/components/search-results-list/search-results-list";
import { FooterNative } from "src/components/footer-native";
import { Platform } from "react-native";

export function NoticiasScreen() {
  ///State para manejar los parametros de búsqueda.
  const [queryParams, setQueryParams] = useState<GetSearchProps>({
    queryParam: "",
    filterField: "type",
    filterParam: "actividades",
  });

  return (
    <Box safeAreaBottom backgroundColor="white" h={"full"} w={"full"}>
      <SearchBar
        color="#800080"
        onChangeQueryParam={(queryParam) =>
          setQueryParams({ ...queryParams, queryParam: queryParam })
        }
      />
      {queryParams?.queryParam.length >= 3 ? (
        <SearchResultsList queryParams={queryParams} />
      ) : (
        <LatestNewsList />
      )}
      {Platform.OS !== "web" && <FooterNative />}
    </Box>
  );
}
