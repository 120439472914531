import { Platform } from "react-native";
import { Helmet } from "react-helmet";

export function Metadata() {
  if (Platform.OS !== "web") {
    return null;
  }

  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="description"
        content="Presenta reclamos y solicitudes a la Municipalidad. Gestiona reclamos, consulta infracciones y accede a información sobre trámites y servicios municipales."
      />
      <meta
        name="keywords"
        content="Reclamos ciudadanos, Gestión de reclamos, Trámites municipales, Consulta de infracciones, Servicios municipales, Impuestos municipales, Corrientes, Municipalidad"
      />
      <meta name="author" content="Municipalidad de la Ciudad de Corrientes" />

      {/*     <!-- Etiquetas Open Graph para compartir en redes sociales --> */}
      <meta
        property="og:title"
        content="Reclamos - Municipalidad de Corrientes"
      />
      <meta
        property="og:description"
        content="Presenta reclamos y solicitudes a la Municipalidad. Gestiona reclamos, consulta infracciones y accede a información sobre trámites y servicios municipales."
      />
      <meta property="og:image" content="URL_de_la_imagen" />
      <meta
        property="og:url"
        content="https://reclamos.ciudaddecorrientes.gov.ar/"
      />
      <meta property="og:type" content="website" />
      {/* <!-- Etiquetas Twitter Card para compartir en Twitter --> */}
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content="Reclamos - Municipalidad de Corrientes"
      />
      <meta
        name="twitter:description"
        content="Presenta reclamos y solicitudes a la Municipalidad. Gestiona reclamos, consulta infracciones y accede a información sobre trámites y servicios municipales."
      />
      <meta name="twitter:image" content="URL_de_la_imagen" />
      <meta name="language" content="Spanish" />

      {/*     <!-- Enlaces relevantes --> */}
      <link
        rel="canonical"
        href="https://reclamos.ciudaddecorrientes.gov.ar/"
      />

      {/* <!-- Google tag (gtag.js) --> */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-P6JP386W2L"
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-P6JP386W2L', {
            page_path: window.location.pathname,
          });
        `}
      </script>
    </Helmet>
  );
}
