import { useQuery } from '@tanstack/react-query';

function getVecinos(text?: string) {
  return async () => {
    if (text) {
      const res = await fetch(
        `${process.env.EXPO_PUBLIC_WEB_API_URL}/buscar/${text}?filter[type]=vecino&sort=-fecha`
      );
      const { data } = await res.json();
      return data;
    } else {
      const res = await fetch(
        `${process.env.EXPO_PUBLIC_WEB_API_URL}/v1.0/vecinos`
      );
      const data = await res.json();
      return data;
    }
  };
}

export function useVecinos(text?: string) {
  return useQuery(['vecinos', text], getVecinos(text));
}
