import { Linking } from 'react-native';
import {
  Ionicons,
  AntDesign,
  Entypo,
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import { Flex, useMediaQuery } from 'native-base';
import { ScreensHeader } from 'src/components/screens-header';
import { CardContact } from '../components/contact-cards';
import { LayoutAuthenticated } from 'src/components/layout-authenticated';
import { ArrowBack } from 'src/components/arrow-back';

export interface ContactItem {
  name: string;
  icon: JSX.Element;
  url: () => Promise<void>;
}

export function ContactoScreen() {
  const [isSmallScreen] = useMediaQuery({
    minWidth: 1024,
  });

  // Links
  const handleYTPress = async () => {
    Linking.openURL('https://www.youtube.com/channel/UCQIaXdSQ5qI3AUMItIErjjA');
  };
  const handleMapsPress = async () => {
    Linking.openURL('https://goo.gl/maps/ojLonXUF1ZsmvwFm7');
  };
  const handlePhonePress = async () => {
    Linking.openURL('tel:080055556864');
  };
  const handleWhatsAppPress = async () => {
    Linking.openURL(
      'https://api.whatsapp.com/send?phone=5493794341768&text=Hola'
    );
  };
  const handleInstaPress = async () => {
    Linking.openURL('https://www.instagram.com/municorrientes/');
  };
  const handleMailPress = async () => {
    Linking.openURL('mailto:informacion@ciudaddecorrientes.gov.ar');
  };
  const handleFacePress = async () => {
    Linking.openURL('https://www.facebook.com/corrientesmuni');
  };
  const handleTwitterPress = async () => {
    Linking.openURL('https://twitter.com/municorrientes');
  };

  // Iconos
  const facebook = (
    <Ionicons name="md-logo-facebook" size={32} color="#075985" />
  );
  const instagram = (
    <Entypo name="instagram-with-circle" size={32} color="#E1306C" />
  );
  const twitter = <AntDesign name="twitter" size={32} color="#22d3ee" />;
  const youtube = <Entypo name="youtube" size={32} color="#dc2626" />;
  const email = (
    <MaterialCommunityIcons name="email-edit" size={32} color="#991b1b" />
  );
  const whatsApp = (
    <FontAwesome5 name="whatsapp-square" size={32} color="#34d399" />
  );
  const location = <FontAwesome name="map-marker" size={32} color="#e11d48" />;
  const phone = <FontAwesome name="phone-square" size={32} color="#fb923c" />;

  // Listas de los datos de Contacto
  const redesInfo: ContactItem[] = [
    {
      name: '/corrientesmuni',
      icon: facebook,
      url: handleFacePress,
    },
    {
      name: '@municorrientes',
      icon: instagram,
      url: handleInstaPress,
    },
    {
      name: '@municorrientes',
      icon: twitter,
      url: handleTwitterPress,
    },
    {
      name: 'Municipalidad de corrientes',
      icon: youtube,
      url: handleYTPress,
    },
  ];

  const contactoInfo: ContactItem[] = [
    {
      name: 'informacion@ciudaddecorrientes.gov.ar',
      icon: email,
      url: handleMailPress,
    },
    {
      name: 'Wpp: 3794 4341768 (#MuniBot)',
      icon: whatsApp,
      url: handleWhatsAppPress,
    },
    {
      name: '147',
      icon: phone,
      url: handlePhonePress,
    },
  ];

  const locationInfo: ContactItem[] = [
    {
      name: '25 de Mayo 1132, W3400 BCN, Corrientes',
      icon: location,
      url: handleMapsPress,
    },
  ];

  return (
    <LayoutAuthenticated>
      <ScreensHeader
        title="Contactos"
        description="Vias de Contacto de la Municipalidad"
      />
      {!isSmallScreen && <ArrowBack route="home" />}
      <Flex px="10" flexWrap="wrap" align="center" justify="center">
        <CardContact
          title="Contactanos"
          contactInfo={contactoInfo}
          justify="space-around"
        />
        <CardContact
          title="Visitanos"
          contactInfo={locationInfo}
          justify="center"
        />
        <CardContact
          title="Seguinos"
          contactInfo={redesInfo}
          justify="space-between"
        />
      </Flex>
    </LayoutAuthenticated>
  );
}
