import {
  Box,
  Button,
  CloseIcon,
  Flex,
  Image,
  ZStack,
  Text,
  InfoIcon,
  Modal,
  Pressable,
  Stack,
  WarningIcon,
} from "native-base";
import { useState } from "react";
import { ImagePreviewModal } from "./image-preview-modal";

interface ImageReclamosGridProps {
  images: Array<string>;
  handleImageRemoved: (index: number) => void;
}

export function ImagesReclamosGrid({
  images,
  handleImageRemoved,
}: ImageReclamosGridProps) {
  const NoImagesAddedInfo = () => (
    <Flex
      flexDirection="row"
      p="3"
      w={{ base: "100%", lg: "200px", xl: "280px" }}
      borderRadius="3"
      borderWidth="2"
      borderColor="info.400"
      bgColor="info.100"
    >
      <InfoIcon size="5" color="info.600" />
      <Text> No se ha cargado ninguna imagen.</Text>
    </Flex>
  );

  const LimitImagesExceeded = () => (
    <Flex
      flexDirection="row"
      p="3"
      w={{ base: "100%", lg: "200px", xl: "280px" }}
      borderRadius="3"
      borderWidth="2"
      borderColor="warning.400"
      bgColor="warning.100"
    >
      <WarningIcon size="5" color="warning.600" />
      <Text> Has Pasado las 4 imagenes Permitidas</Text>
    </Flex> 
  );

  const ImageItem = ({ image, index }: { image: string; index: number }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
      <Pressable onPress={() => setIsOpen(!isOpen)} m="1">
        <Box key={`${index}`} h="100px" w="100px">
          <ZStack>
            <Image
              key={index}
              source={{ uri: image }}
              size="20"
              alt={`image ${index}`}
              borderRadius="10px"
              borderColor="black"
              borderWidth="3px"
            />
            <Button
              key={`button ${index}`}
              onPress={() => handleImageRemoved(index)}
              bgColor="red.600"
              leftIcon={<CloseIcon size="3" />}
              alignSelf="flex-end"
              p="3"
              mt="-3"
              mr="1"
              borderRadius="16px"
            />
          </ZStack>
          <Modal size="lg" isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
            <ImagePreviewModal imageUri={image} />
          </Modal>
        </Box>
      </Pressable>
    );
  };

  return (
    <Stack w="100%" mt="3" space={3} flexWrap="wrap" direction="row" bg={"pink"}>
      {images.length > 0 ? (
        images.map((image, i) => (
          <ImageItem key={i} image={image} index={i} />
        ))
      ) : (
          <NoImagesAddedInfo />
      )}
      {images.length > 4
        ? (
          <LimitImagesExceeded />
        )
        : null
      }
    </Stack>
  );
}
