import { Box, Divider, HamburgerIcon, Menu, Pressable } from "native-base";
import { TouchableHighlight } from "react-native";
import { NavItem } from "./nav-item";
import { StackNavigationProp } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import { RootStackParamList } from "src/navigator";
import { useAppContext } from "src/contexts/app-context";

import Home from "../../assets/icons/home.png";
import Hammer from "../../assets/icons/hammer.png";
import List from "../../assets/icons/reclamos-list.png";
import Edit from "../../assets/icons/edit-person.png";
import Phone from "../../assets/icons/phone.png";
import Sign from "../../assets/icons/sign.png";
import Ts from "../../assets/icons/ts.png";

export const MenuMobile = () => {
  const { token, logout } = useAppContext();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const handleItemPress = (screen: any) => () => {
    navigation.navigate(screen);
  };

  function handleLogout() {
    logout();
    navigation.navigate("home");
  }

  return (
    <Box w="100%" alignItems="center">
      <Menu
        w="300px"
        mt={{ base: "4", sm: "4" }}
        ml={{ base: "1.5", sm: "1.5" }}
        bg="#F6FFF8"
        trigger={(triggerProps) => {
          return (
            <Pressable {...triggerProps}>
              <HamburgerIcon ml="1" size={26} color="#004c35" />
            </Pressable>
          );
        }}
      >
        <Menu.Group title="Municipalidad de Corrientes">
          {Boolean(token) ? (
            <Box>
              <TouchableHighlight underlayColor="transparent">
                <NavItem image={Home} navigate={handleItemPress("home")}>
                  Inicio
                </NavItem>
              </TouchableHighlight>
              <TouchableHighlight underlayColor="transparent">
                <NavItem
                  image={Hammer}
                  navigate={handleItemPress("tipo-problema")}
                >
                  Nuevo Reclamo
                </NavItem>
              </TouchableHighlight>
              <TouchableHighlight underlayColor="transparent">
                <NavItem
                  image={List}
                  navigate={handleItemPress("mis-reclamos")}
                >
                  Mis reclamos
                </NavItem>
              </TouchableHighlight>
              <TouchableHighlight underlayColor="transparent">
                <NavItem image={Ts} navigate={handleItemPress("transporte")}>
                  Mi Transporte Seguro
                </NavItem>
              </TouchableHighlight>
              <TouchableHighlight underlayColor="transparent">
                <NavItem image={Phone} navigate={handleItemPress("contacto")}>
                  Contacto
                </NavItem>
              </TouchableHighlight>
              <TouchableHighlight underlayColor="transparent">
                <NavItem
                  image={Edit}
                  navigate={handleItemPress("editar-perfil")}
                >
                  Editar Perfil
                </NavItem>
              </TouchableHighlight>
            </Box>
          ) : (
            <TouchableHighlight underlayColor="transparent">
              <NavItem image={Home} navigate={handleItemPress("home")}>
                Inicio
              </NavItem>
            </TouchableHighlight>
          )}
        </Menu.Group>
        <Divider mt="3" w="100%" />
        {Boolean(token) ? (
          <TouchableHighlight underlayColor="transparent">
            <NavItem image={Sign} navigate={handleLogout}>
              Cerrar Sesión
            </NavItem>
          </TouchableHighlight>
        ) : (
          <TouchableHighlight underlayColor="transparent">
            <NavItem image={Sign} navigate={handleItemPress("login")}>
              Iniciar Sesión
            </NavItem>
          </TouchableHighlight>
        )}
      </Menu>
    </Box>
  );
};
