import { WebView } from "react-native-webview";

export function TurismoScreen() {
  return (
    <WebView
      source={{ uri: "https://visitcorrientes.tur.ar" }}
      allowsBackForwardNavigationGestures
      ignoreSslError={true}
    />
  );
}
