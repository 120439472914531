import { StyleSheet, TouchableHighlight } from "react-native";
import {
  ScrollView,
  HStack,
  Text,
  Spacer,
  Button,
  ChevronDownIcon,
  InfoOutlineIcon,
  ChevronUpIcon,
  Avatar,
  View,
  Stack,
  Center,
  Divider,
  PresenceTransition,
} from "native-base";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "src/navigator";
import { useState } from "react";
import Octicons from "@expo/vector-icons/Octicons";

// create a component
export function Tramites({ tramite }: any) {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [showList, setShowList] = useState<any>(false);

  return (
    <ScrollView>
      <View style={styles.boxList}>
        <TouchableHighlight
          underlayColor="transparent"
          onPress={() => setShowList(!showList)}
        >
          <HStack space={[2, 3]} paddingY={2} justifyContent="space-between">
            <Avatar source={{ uri: tramite.icon }} size="md" />
            <Text flex={10} style={styles.titleList}>
              {tramite.name.toUpperCase()}
            </Text>
            <Spacer />
            {showList ? (
              <ChevronUpIcon size="5" color="gray.900" marginTop={4} />
            ) : (
              <ChevronDownIcon size="5" color="gray.900" marginTop={4} />
            )}
          </HStack>
        </TouchableHighlight>
      </View>
      {showList &&
        tramite.tramites.map((tramite: any, key: any) => (
          <View key={key}>
            <Divider px="10" />
            <TouchableHighlight
              underlayColor="transparent"
              onPress={() =>
                navigation.navigate("tramite-detail", {
                  link: tramite.link,
                  title: tramite.title,
                })
              }
            >
              <PresenceTransition
                visible={showList}
                initial={{
                  opacity: 0,
                  scale: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 250,
                  },
                }}
              >
                <Stack direction="row" mb="2.5" mt="1.5" px={4} space={[2, 4]} >
                  <Center ml={4}>
                    <Text style={styles.titleItem}>{tramite.title}</Text>
                  </Center>
                  <Spacer />
                  <Center>
                    <Button
                      style={styles.buttonList}
                      onPress={() =>
                        navigation.navigate("tramite-detail", {
                          link: tramite.link,
                          title: tramite.title,
                        })
                      }

                    >
                      <Octicons name="plus" color="#5299C1" size={30} />
                    </Button>
                  </Center>
                </Stack>
              </PresenceTransition>
            </TouchableHighlight>
          </View>
        ))}
    </ScrollView>
  );
}

// define your styles
const styles = StyleSheet.create({
  boxList: {
    paddingHorizontal: 10,
    marginVertical: 3,
  },
  titleItem: {
    paddingVertical: 6,
    paddingHorizontal: 4,
    maxWidth: 300,
    color: "black",
  },
  titleList: {
    paddingTop: 14,
    paddingLeft: 6,
    color: "black",
  },
  buttonList: {
    color: "black",
    backgroundColor: "transparent",
  },
});
