import {
  View,
  Button,
  Center,
  Stack,
  Image,
  Text,
  useBreakpointValue,
  Modal,
  Popover,
} from 'native-base';
import React, { useState } from 'react';
import { AntDesign } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import logoMuni from '../../../../assets/mcc_logo.png';

export function CarrouselImageWeb({ images }: any) {
  const [showModal, setShowModal] = useState(false);
  const [size, setSize] = React.useState('md');
  const [activeTab, setActiveTab] = useState(0);
  const topSpace = useBreakpointValue({
    base: 250,
    md: 350,
    lg: 400,
    xl: 350,
  });
  const imageSize = useBreakpointValue({
    base: 400,
    md: 600,
    lg: 620,
    xl: 620,
  });
  const sizes: string[] = ['xs', 'sm', 'md', 'lg', 'xl', 'full'];

  const handleTabChange = (tabIndex: any) => {
    setActiveTab(tabIndex);
  };

  const handleSizeClick = (newSize: string) => {
    setSize(newSize);
    setShowModal(!showModal);
  };

  return (
    <View>
      <Modal
        size={size}
        p="2"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <Modal.Content shadow={0} bg="contrastThreshold">
          <Image
            source={{
              uri: `${process.env.EXPO_PUBLIC_SISTEMAS_WEB_URL}/${images?.data?.fotos[activeTab]?.nombre_foto}`,
            }}
            fallbackSource={logoMuni}
            alt="reclamo image"
            w={Boolean(size !== 'full') ? size : '100%'}
            h={Boolean(size !== 'full') ? size : '80vh'}
          />
        </Modal.Content>
      </Modal>

      <Stack
        direction="row"
        justifyContent="space-between"
        w="100%"
        zIndex={2}
        top={topSpace}
        position="absolute"
      >
        <Button
          rounded="full"
          variant="ghost"
          colorScheme="none"
          mr={{ base: 0, lg: '5', xl: '1' }}
          isDisabled={activeTab < 1}
          onPress={() => handleTabChange(activeTab - 1)}
        >
          <AntDesign name="arrowleft" size={32} color="rgb(219, 39, 119)" />
        </Button>
        <Button
          rounded="full"
          variant="ghost"
          colorScheme="none"
          ml={{ base: 0, lg: '5', xl: '1' }}
          isDisabled={activeTab === images?.data?.fotos.length - 1}
          onPress={() => handleTabChange(activeTab + 1)}
        >
          <AntDesign name="arrowright" size={32} color="rgb(219, 39, 119)" />
        </Button>
      </Stack>
      <Center
        w="100%"
        position="absolute"
        mt={{ base: '16', lg: '10', xl: '16' }}
        p={{ base: 2, lg: 12, xl: 12, '2xl': 3 }}
      >
        <Image
          source={{
            uri: `${process.env.EXPO_PUBLIC_SISTEMAS_WEB_URL}/${images?.data?.fotos[activeTab]?.nombre_foto}`,
          }}
          // fallbackSource={logoMuni}
          alt="reclamo image"
          size={imageSize}
        />
      </Center>
      <Text
        textAlign="center"
        fontSize={20}
        fontWeight="semibold"
        color="#004c35"
      >
        Imagén del reclamo {activeTab + 1}
      </Text>

      <Popover
        trigger={(triggerProps) => {
          return (
            <Button
              {...triggerProps}
              display={{ base: 'none', lg: 'block' }}
              colorScheme="none"
              variant="ghost"
              mt={{ base: 0, xl: '8', '2xl': 'none' }}
              mr={{ base: 0, lg: '8', xl: '16' }}
              alignSelf="self-end"
            >
              <MaterialCommunityIcons
                name="image-filter-center-focus-weak"
                size={24}
                color="black"
              />
            </Button>
          );
        }}
      >
        <Popover.Content mr="8">
          <Popover.CloseButton />
          <Popover.Header>
            Selecciona un tamaño para visualizar el tamaño que deseas
          </Popover.Header>
          <Popover.Body>
            <Stack flexWrap="wrap" direction="row" space={4}>
              {sizes.map((size) => {
                return (
                  <Button
                    colorScheme="emerald"
                    onPress={() => handleSizeClick(size)}
                    key={size}
                  >
                    {`Abrir Imagen ${size}`}
                  </Button>
                );
              })}
            </Stack>
          </Popover.Body>
        </Popover.Content>
      </Popover>
    </View>
  );
}
