import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Share } from 'react-native';
import { Box, Center, HStack, ScrollView, Text, View } from 'native-base';
import { useRoute, RouteProp } from '@react-navigation/native';
import FontAwesome from '@expo/vector-icons/FontAwesome';

import { RootStackParamList } from 'src/navigator';
import { Loading } from 'src/components/loading';
import { AccordionDetails } from 'src/components/accordion-details';

const onShare = async (link: any) => {
  try {
    const result = await Share.share({
      message: link,
    });

    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        console.log('1');
        // shared with activity type of result.activityType
      } else {
        console.log('2');
        // shared
      }
    } else if (result.action === Share.dismissedAction) {
      console.log('3');
      // dismissed
    }
  } catch (error: any) {
    alert(error.message);
  }
};

export function TramiteDetailScreen() {
  const [tramite, setTramite] = useState<any>();
  const [link, setLink] = useState<any>('');
  const { params }: any = useRoute<RouteProp<RootStackParamList>>();

  const getMainTramites = async () => {
    const res = await fetch(params?.link);
    const data = await res.json();

    setTramite(data);
    setLink(`${data.title} ${data.link}`);
  };

  useEffect(() => {
    getMainTramites();
  }, []);

  if (!tramite) {
    return (
      <Center h="full">
        <Loading />
      </Center>
    );
  }

  //http://ciudaddecorrientes.gov.ar/api/v1.0/tramites
  //http://ciudaddecorrientes.gov.ar/api/v1.0/tramites/20057

  return (
    <ScrollView>
      <Box w="full">
        <Box>
          <Text fontSize="2xl" bold>
            {tramite.title}
          </Text>
          <div dangerouslySetInnerHTML={{ __html: tramite.content }} />
          {tramite.txt_items.map((txt_items: any, key: number) => (
            <View key={key}>
              <AccordionDetails
                title={txt_items.title}
                txt_content={txt_items.content}
              />
            </View>
          ))}
        </Box>
        <Box mt="4" mb="8" px="2" w="full">
          <HStack flex={1} alignItems="center" justifyContent="space-between">
            <FontAwesome
              name="share-alt"
              style={styles.footerIcon}
              onPress={() => onShare(link)}
            />
            <FontAwesome
              name="whatsapp"
              style={styles.footerIcon}
              onPress={() => onShare(link)}
            />
            <FontAwesome
              name="twitter"
              style={styles.footerIcon}
              onPress={() => onShare(link)}
            />
            <FontAwesome
              name="facebook-square"
              style={styles.footerIcon}
              onPress={() => onShare(link)}
            />
          </HStack>
        </Box>
      </Box>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  footerIcon: {
    fontSize: 22,
    color: '#0087ff',
  },
});
