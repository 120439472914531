import GoogleMapReact from "google-map-react";
import { Button, View, Text } from "native-base";
import { useState, useRef, useEffect } from "react";
import { getLocation } from "../helper/geoLocation";
import { StyleSheet } from "react-native";

interface PositionProps {
  onChange: (coordenadas: any) => void;
}

export const MapComponentWeb = ({ onChange }: PositionProps) => {
  const [loading, setLoading] = useState(true);
  const mapRef = useRef<any>();
  const mapsRef = useRef<any>();
  const markerRef = useRef<any>();
  const [initialCoords, setInitialCoords] = useState<any>();

  const handleMapClick = (event: GoogleMapReact.ClickEventValue) => {
    if (mapRef.current) {
      markerRef.current?.setMap(null);

      const coordenadas = {
        lat: event.lat,
        lng: event.lng,
      };

      const marker = new mapsRef.current.Marker({
        position: coordenadas,
        map: mapRef.current,
      });

      markerRef.current = marker;

      onChange(coordenadas);
    }
  };

  useEffect(() => {
    if (!loading && initialCoords) {
      new mapsRef.current.Marker({
        position: initialCoords,
        map: mapRef.current,
        icon: {
          path: mapsRef.current.SymbolPath.CIRCLE,
          scale: 10,
          fillOpacity: 1,
          strokeWeight: 2,
          fillColor: "#5384ED",
          strokeColor: "#ffffff",
        },
      });
    }
  }, [loading, initialCoords]);

  const handleUserLocation = () => {
    getLocation()
      .then((position: GeolocationPosition) => {
        const coordenadas = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
  
        mapRef.current?.setCenter(coordenadas);
        mapRef.current?.panTo(coordenadas);
        setInitialCoords(coordenadas);
      })
      .catch((error: Error) => {
        console.error(error);
      });
  };

  return (
    <View
      w={{ base: "300px", lg: "200px", xl: "280px" }}
      h={{ base: "300px", lg: "200px", xl: "280px" }}
    >
      <Button
        onPress={handleUserLocation}
        position="absolute"
        top="10px"
        left="10px"
        zIndex="999"
      >
        Mi Ubicación
      </Button>

      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCqnPScpFdHfNvkFU88yTwuTfFUBOc2ozU" }}
        defaultCenter={{
          lat: -27.46758,
          lng: -58.83461,
        }}
        defaultZoom={15}
        onClick={handleMapClick}
        onGoogleApiLoaded={({ maps, map }) => {
          mapRef.current = map;
          mapsRef.current = maps;

          setLoading(false);
        }}
        yesIWantToUseGoogleMapApiInternals
      />
    </View>
  );
};

const styles = StyleSheet.create({
  locationButton: {
    position: "absolute",
    top: 20,
    left: 20,
    zIndex: 999,
  },
  buttonText: {
    color: "white",
    fontSize: 14,
  },
});
