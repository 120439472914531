import { useState, useRef } from "react";
import {
  Text,
  Button,
  Box,
  Select,
  Input,
  HStack,
  Center,
  Flex,
  TextArea,
  Modal,
  useBreakpointValue,
  useDisclose,
} from "native-base";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useAppContext } from "../../../contexts/app-context";
import { RootStackParamList } from "src/navigator";
import { useConfig } from "../queries/use-config";
import { Loading } from "src/components/loading";
import { MapComponentWeb } from "../components/map-web";
import * as ImagePicker from "expo-image-picker";
import * as yup from "yup";
import { useAddReclamo } from "../queries/use-add-reclamo";
import { ArrowBack } from "src/components/arrow-back";
import { ImagesReclamosGrid } from "../components/images-reclamos-grid";
import { ScreensHeader } from "src/components/screens-header";
import { ErrorModel } from "src/components/error-modal";
import { SuccessModel } from "src/components/success-modal";
import { LayoutAuthenticated } from "src/components/layout-authenticated";

// Reducir calida
import * as ImageManipulator from 'expo-image-manipulator';

const schema = yup.object().shape({
  id_grupo_problema: yup
    .string()
    .required("Debe seleccionar un tipo de problema."),
  id_problema: yup.string().required("Debe seleccionar un problema."),
  descripcion: yup.string(),
  otro_dato_ubicacion: yup.string(),
});

export function ReclamosScreen({ route }: any) {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { id_problema, id_grupo_problema } = route.params || {};
  const { user, token } = useAppContext();
  const { data: config, isLoading } = useConfig(token);
  const { mutateAsync } = useAddReclamo(token);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ubicacionSeleccionada, setUbicacionSeleccionada] =
    useState<boolean>(false);
  const [images, setImages] = useState<Array<string>>([]);
  const [reclamoId, setReclamoId] = useState<number | null>(null);
  const { isOpen, onOpen, onClose } = useDisclose();
  const dimensions = useBreakpointValue({
    base: "300px",
    lg: "200px",
    xl: "280px",
  });

  // hanlder ref for focus
  const otroDatoUbicacionRef = useRef(null);
  const descripcionRef = useRef(null);
  const myMapRef = useRef(null);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    setError
  } = useForm({
    defaultValues: {
      id_grupo_problema: String(id_grupo_problema),
      id_problema: String(id_problema),
      descripcion: "",
      otro_dato_ubicacion: "",
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [coordenadas, setCoordenadas] = useState<any>("");
  const watchIdGrupoProblema = watch("id_grupo_problema");

  async function onSubmit(data: any) {

     // Validacion requeridos
    if(!validateInputs(data)) return;
  
    if (coordenadas) {
      try {
        onOpen();
        const response = await mutateAsync({
          ...data,
          grupo_problema: data.id_grupo_problema,
          coordenadas,
          images,
          dni: user.dni,
          celular: user.celular,
        });

        if(!response.data){
           setErrorMessage(response.message);
           setShowError(true);
           return;
        }

        const idContacto = response?.data[0]?.id_contacto;
        setReclamoId(idContacto);
        setShowSuccess(true);
        navigation.push("mis-reclamos");

      } catch (error: any) {
        setErrorMessage(error);
        setShowError(true);
      }
    }else{
      myMapRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // Función para seleccionar imágenes
  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 0.5,
      allowsMultipleSelection: true,
      selectionLimit: 4
    });
    if (result && !result.canceled && result.assets) {
      const selectedImages = result.assets.map(async (asset) => {
        // Optimizar la imagen antes de agregarla
        const manipulatedImage = await ImageManipulator.manipulateAsync(
          asset.uri,
          [{ resize: { width: 800 } }], // Ajusta el tamaño de la imagen según lo necesario
          { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG } // Comprime la imagen al 70% de calidad en formato JPEG
        );
        return manipulatedImage.uri;
      });
      // Esperar a que todas las imágenes se optimicen antes de agregarlas al estado
      const optimizedImages = await Promise.all(selectedImages);
      setImages((prevImages) => [...prevImages, ...optimizedImages]);
    }
  };

  function handleImageRemoved(index: number) {
    setImages(images.filter((image, i) => i !== index));
  }

  function handleMapChange(coordenadas: any) {
    setCoordenadas(coordenadas);
    setUbicacionSeleccionada(true);
  }

  if (isLoading) {
    return <Loading />;
  }

  const validateInputs = (data: any): boolean  => {
    if (!data.otro_dato_ubicacion.trim()) {
      setError("otro_dato_ubicacion", {
        message: "Este campo es requerido!"
      })
      otroDatoUbicacionRef.current?.focus();
      return false
    }else if (!data.descripcion.trim()) {
      setError("descripcion", {
        message: "Este campo es requerido!"
      })
      descripcionRef.current?.focus();
      return false
    }else if(data.descripcion.length <= 20){
      setError("descripcion", {
        message: "La descripcion debe tener un minimo 20 caracteres"
      })
      descripcionRef.current?.blur();
      descripcionRef.current?.focus();
      return false;
    }

    return true;
  }

  return (
    <LayoutAuthenticated>
      <Modal
        h="full"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        {isSubmitting ? (
          <Loading />
        ) : (
          <Modal.Content shadow={0} bg="contrastThreshold">
            <Modal.CloseButton
              mr="1"
              mt="1"
              _hover={{
                bg: "contrastThreshold",
              }}
            />
            {showError && (
              <Modal.Body>
                <ErrorModel
                  errorTitle="Ocurrio un error, verifique los datos!"
                  errorMessage={errorMessage}
                />
              </Modal.Body>
            )}
            {showSuccess && (
              <Modal.Body>
                <SuccessModel
                  successTitle={`Reclamo Nro: ${reclamoId}, creado exitosamente!`}
                  successMessage=""
                />
              </Modal.Body>
            )}
          </Modal.Content>
        )}
      </Modal>
      <ScreensHeader
        title="Nuevo Reclamo"
        description="Complete el formulario"
      />
      <ArrowBack route="tipo-problema" />
      <Flex my="8" justifyContent={{ base: "center" }}>
        <HStack
          flexWrap="wrap"
          justifyContent={{
            base: "center",
            lg: "space-evenly",
            xl: "space-evenly",
          }}
          space={{ base: 1, lg: 3, xl: 4 }}
        >
          <Box>
            <Text fontWeight="semibold">Tipo de Problema</Text>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder={"Seleccione un problema"}
                  selectedValue={value}
                  onValueChange={(newValue) => {
                    onChange(newValue);
                    setValue("id_problema", "");
                  }}
                  w={dimensions}
                  borderColor="#5299C1"
                >
                  {config?.problemas?.map((problemaGroup: any) => (
                    <Select.Item
                      key={problemaGroup.id_grupo_problema}
                      label={problemaGroup.descripcion}
                      value={String(problemaGroup.id_grupo_problema)}
                    />
                  ))}
                </Select>
              )}
              name="id_grupo_problema"
            />
            {Object.keys(errors.id_grupo_problema || {}).length > 0 && (
              <Text color="red.500">{errors.id_grupo_problema?.message}</Text>
            )}
          </Box>
          <Box>
            <Text fontWeight="semibold">Problema</Text>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder={"Seleccione el problema"}
                  selectedValue={value}
                  onValueChange={(newValue) => {
                    onChange(newValue);
                  }}
                  isDisabled={!watchIdGrupoProblema}
                  w={dimensions}
                  borderColor="#5299C1"
                >
                  {config?.problemas
                    ?.find(
                      (problema: any) =>
                        problema.id_grupo_problema ===
                        Number(watchIdGrupoProblema)
                    )
                    ?.problemas?.map((problema: any) => (
                      <Select.Item
                        key={problema.id_problema}
                        label={problema.asunto}
                        value={String(problema.id_problema)}
                      />
                    ))}
                </Select>
              )}
              name="id_problema"
            />
            {Object.keys(errors.id_problema || {}).length > 0 ? (
              <Text color="red.500">{errors.id_problema?.message}</Text>
            ) : null}
          </Box>
         
          <Box pr={{ base: "0", lg: "6", xl: "6" }} ref={myMapRef}>
            <Text fontWeight="semibold">Domicilio</Text>
            <Controller
              control={control}
              render={({ field: { onChange } }) => (
                <Input
                  ref={otroDatoUbicacionRef}
                  placeholder="Domicilio (CALLE Y ALTURA)"
                  onChange={onChange}
                  w={dimensions}
                  borderColor="#5299C1"
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Enter") {
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
              )}
              name="otro_dato_ubicacion"
              defaultValue=""
            />
            {errors.otro_dato_ubicacion && (
              <Text color="red.500">{errors.otro_dato_ubicacion.message}</Text>
            )}
          </Box>
        </HStack>
        <HStack
          mt="20px"
          flexWrap="wrap"
          justifyContent={{
            base: "center",
            lg: "space-evenly",
            xl: "space-evenly",
          }}
          space={{ base: 1, lg: 3, xl: 3 }}
        >
          <Flex direction="column">
            <Text
              fontWeight="semibold"
              textAlign={{ base: "center", xl: "start" }}
              w={dimensions}
            >
              Seleccione la ubicación en el mapa:
            </Text>
            <MapComponentWeb onChange={handleMapChange} />
            {ubicacionSeleccionada ? (
              <Text
                textAlign="center"
                w={dimensions}
                color="emerald.700"
                mt="1"
              >
                Ubicación seleccionada correctamente.
              </Text>
            ) : (
              <Text textAlign="center" w={dimensions} color="red.700" mt="1">
                Debe seleccionar una ubicación en el mapa*
              </Text>
            )}
          </Flex>
          <Box mt={{ base: "6" }}>
            <Text fontWeight="semibold">Descripción del Reclamo</Text>
            <Controller
              control={control}
              render={({ field: { onChange } }) => (
                <TextArea
                  ref={descripcionRef}
                  onChange={onChange}
                  w={dimensions}
                  h={dimensions}
                  fontWeight="semibold"
                  autoCompleteType={undefined}
                />
              )}
              name="descripcion"
            />
            {errors.descripcion && (
              <Text color="red.500">{errors.descripcion.message}</Text>
            )}
          </Box>
          <Box
            alignItems={{ base: "center", lg: "none", xl: "none" }}
            mt={{ base: "6" }}
          >
            <Text fontWeight="semibold">Imagenes:</Text>
            <Center
              w={dimensions}
              h={dimensions}
              borderColor="#D0D5DD"
              borderStyle="dashed"
              borderWidth="2"
              textAlign="center"
            >
              <Text fontWeight="bold" mt="20px">
                Cargar Imágenes
              </Text>
              <Text px="6">
                Navegá y elegí hasta 4 fotos desde tu galería o cámara de fotos
              </Text>
              <Button
                onPress={pickImage}
                isDisabled={images.length > 4}
                background="#004C35"
                mt="5px"
              >
                +
              </Button>
            </Center>
            <ImagesReclamosGrid
              images={images}
              handleImageRemoved={handleImageRemoved}
            />
          </Box>
        </HStack>
        <Box>
          <Button
            bg="#5299C1"
            mt="20px"
            w="50%"
            alignSelf="center"
            onPress={handleSubmit(onSubmit)}
            isDisabled={isSubmitting || images.length > 4}
            isLoading={isSubmitting}
            isLoadingText="Procesando el Reclamo"
          >
            <Text color="#FFFFFF">Enviar</Text>
          </Button>
        </Box>
      </Flex>
    </LayoutAuthenticated>
  );
}
