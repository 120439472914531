import React, { useState } from "react";
import { Box } from "native-base";
import { TramiteList } from "../components/tramite-list";
import { GetSearchProps } from "src/features/busqueda/queries/use-search";
import { SearchBar } from "src/features/busqueda/components/search-bar/search-bar";
import { SearchResultsList } from "src/features/busqueda/components/search-results-list/search-results-list";
import { Platform } from "react-native";
import { FooterNative } from "src/components/footer-native";

export function TramitesScreen() {
  const [queryParams, setQueryParams] = useState<GetSearchProps>({
    queryParam: "",
    filterField: "type",
    filterParam: "tramite",
  });

  return (
    <>
      <Box bg="white" h={"full"}>
        <SearchBar
          color="#0087ff"
          onChangeQueryParam={(queryParam) =>
            setQueryParams({ ...queryParams, queryParam: queryParam })
          }
        />
        {queryParams.queryParam.length >= 3 ? (
          <SearchResultsList queryParams={queryParams} />
        ) : (
          <TramiteList />
        )}
      </Box>

      {Platform.OS !== "web" && <FooterNative />}
    </>
    
  );
}
