import React, { useEffect, useState } from 'react';

import { Platform } from 'react-native';
import { Loading } from 'src/components/loading';
import { useAppContext } from 'src/contexts/app-context';
import { LoginScreen } from 'src/features/login/screens/login.screen';
import { LoginScreen as LoginScreenNative } from 'src/features/login/screens/login.screen.native';

const AuthGuard = ({ children }: { children: React.ReactNode }) => {

  const { token } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<null | string>(null);

  useEffect(() => {
    const checkAuthentication = () => {
      setIsLoading(false);
      setUser(token);
    };

    checkAuthentication();
  }, []);


  return (
     isLoading ? (
      <Loading />
     ):(
      user ? (
        React.cloneElement(children as React.ReactElement) 
      ) : Platform.OS === "web" ? <LoginScreen /> : <LoginScreenNative />
     )
      
  );
};

export default AuthGuard;
