import { Box } from "native-base";
import { useState } from "react";
import { GetSearchProps } from "src/features/busqueda/queries/use-search";
import { SearchBar } from "src/features/busqueda/components/search-bar/search-bar";
import { SearchResultsList } from "src/features/busqueda/components/search-results-list/search-results-list";
import { LatestEventList } from "src/features/eventos/components/latest-events-list/latest-event-list";
import { FooterNative } from "src/components/footer-native";
import { Platform } from "react-native";
import { LayoutAuthenticated } from "src/components/layout-authenticated";

export function EventosScreen() {
  ///State para manejar los parametros de búsqueda.
  const [queryParams, setQueryParams] = useState<GetSearchProps>({
    queryParam: "",
    filterField: "type",
    filterParam: "agenda_cultural",
  });

  return (
    <>
     <Box bg={"white"} h={"full"} mb={16}>
        <SearchBar
          onChangeQueryParam={(queryParam) =>
            setQueryParams({ ...queryParams, queryParam: queryParam })
          }
          color="#ff6600"
        />
        {queryParams?.queryParam.length >= 3 ? (
          <SearchResultsList queryParams={queryParams} />
        ) : (
          <LatestEventList />
        )}
      </Box>  
      {Platform.OS !== "web" && <FooterNative />}
    </>
   
  );
}
