import { Box, ScrollView } from "native-base";
import { useState } from "react";
import { VecinosData } from "../components/vecinos-data";
import { SearchBar } from "src/features/busqueda/components/search-bar/search-bar";
import { GetSearchProps } from "src/features/busqueda/queries/use-search";
import { SearchResultsList } from "src/features/busqueda/components/search-results-list/search-results-list";
import { Platform } from "react-native";
import { FooterNative } from "src/components/footer-native";

export function VecinosScreen() {
  const [queryParams, setQueryParams] = useState<GetSearchProps>({
    queryParam: "",
    filterField: "type",
    filterParam: "vecino",
  });

  return (
    <>
      <ScrollView bg="white" h={"full"}>
        <Box>
          <SearchBar
            color="#00cc99"
            onChangeQueryParam={(queryParam) =>
              setQueryParams({ ...queryParams, queryParam: queryParam })
            }
          />
          {queryParams?.queryParam.length >= 3 ? (
            <SearchResultsList queryParams={queryParams} />
          ) : (
            <VecinosData />
          )}
        </Box>
      </ScrollView>
      {Platform.OS !== "web" && <FooterNative />}
    </>
   
  );
}
