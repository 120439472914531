import { Box, HStack, Image, Text } from "native-base";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import React from "react";
import { Linking, Platform, Pressable } from "react-native";

import Logo from "../../assets/logo-circle.png";

const handleYTPress = async () => {
  Linking.openURL("https://www.youtube.com/channel/UCQIaXdSQ5qI3AUMItIErjjA");
};
const handleWhatsAppPress = async () => {
  Linking.openURL("https://api.whatsapp.com/send?phone=5493794341768&text=Hola");
};
const handleInstaPress = async () => {
  Linking.openURL("https://www.instagram.com/municorrientes/");
};
const handleFacePress = async () => {
  Linking.openURL("https://www.facebook.com/corrientesmuni");
};
const handleMuniPress = async () => {
  Linking.openURL("https://ciudaddecorrientes.gov.ar/");
};

export function FooterWebMobile() {
  if (Platform.OS !== "web") {
    return null;
  }

  return (
    <Box
      bottom={0}
      h="20"
      alignContent="center"
      position="sticky"
      justifyContent="center"
      bg="#F6FFF8"
    >
      <Box w="full">
        <HStack justifyContent="space-evenly">
          <FontAwesome
            name="whatsapp"
            size={22}
            color="#004c35"
            onPress={handleWhatsAppPress}
          />
          <FontAwesome
            name="youtube-play"
            size={22}
            color="#004c35"
            onPress={handleYTPress}
          />
          <Pressable onPress={handleMuniPress} style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
          }}>
            <Image source={Logo} alt="muni-logo" size="6" />
            <Text fontWeight="normal" color="#A3A3A3">Versión: {process.env.REACT_APP_VERSION}</Text>
          </Pressable>
          <FontAwesome
            name="instagram"
            size={22}
            color="#004c35"
            onPress={handleInstaPress}
          />
          <FontAwesome
            name="facebook-square"
            size={22}
            color="#004c35"
            onPress={handleFacePress}
          />
        </HStack>
      </Box>
    </Box>
  );
}
