import { SafeAreaProvider } from "react-native-safe-area-context";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/es";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Main } from "./src/main";

dayjs.locale("es");
dayjs.extend(localeData);
dayjs.extend(updateLocale);

dayjs.updateLocale("es", {
  weekdays: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ],
  months: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
});

const queryClient = new QueryClient();

export default function App() {
  return (
    <SafeAreaProvider>
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </SafeAreaProvider>
  );
}
