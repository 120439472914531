import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  Box,
  HStack,
  Hidden,
  Text,
  Menu,
  Pressable,
  VStack,
  useMediaQuery,
  Stack
} from 'native-base';
import { ImageBackground, Platform, StyleSheet } from 'react-native';
import { useAppContext } from 'src/contexts/app-context';
import Octicons from '@expo/vector-icons/Octicons';

// @ts-ignore
import logo from '../../assets/logo-header.png';
import { RootStackParamList } from '../navigator';
import { MenuMobile } from './menu-mobile';

export function HeaderWeb() {
  const { user } = useAppContext();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [isSmallScreen] = useMediaQuery({
    minWidth: 1024,
  });

  if (Platform.OS !== 'web') {
    return null;
  }

  return (
    <HStack
      bg="#F6FFF8"
      px="1"
      py="4"
      zIndex={2}
      top={0}
      position="sticky"
      justifyContent="space-between"
      alignItems="center"
      w="100%"
    >
      <Hidden from={isSmallScreen ? "lg" : "none"}>
        <Box alignItems="center">
          <MenuMobile />
        </Box>
      </Hidden>
      <HStack alignItems="center" flex={1} justifyContent="center">
        <Pressable onPress={() => navigation.navigate('home')}>
          <ImageBackground source={logo} style={styles.logo} />
        </Pressable>
      </HStack>
      {user && (
        <Box px="4" py="3" mr="0" bg="gray.100" rounded="full">
          <Menu
            ml="2"
            w="190"
            trigger={(triggerProps) => {
              return (
                <Pressable
                  accessibilityLabel="More options menu"
                  {...triggerProps}
                >
                  <Octicons name="person-fill" size={26} color="#004c35" />
                </Pressable>
              );
            }}
          >
            {isSmallScreen ? (
              <Menu.Item
                _text={{
                  color: '#004c35',
                  fontWeight: 'medium',
                  fontSize: 16,
                }}
                onPress={() => navigation.navigate('editar-perfil')}
              >
                Editar Perfil
              </Menu.Item>
            ) : (
              <Stack direction="column" mt="1" mx="2">
                <Menu.Item
                  _text={{
                    color: '#004c35',
                    fontWeight: 'medium',
                    fontSize: 16,
                  }}
                  rounded="xl"
                  onPress={() => navigation.navigate('editar-perfil')}
                >
                  Editar Perfil
                </Menu.Item>
                <Box alignSelf="center">
                  <Text
                    textAlign="left"
                    color="#004c35"
                    fontWeight="medium"
                    fontSize={16}
                  >
                    {`${user.lastname}, ${user.name}`}
                  </Text>
                  <Text color="#004c35">{user.email}</Text>
                </Box>
              </Stack>
            )}
          </Menu>
        </Box>
      )}
    </HStack>
  );
}

const styles = StyleSheet.create({
  logo: {
    width: 230,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
