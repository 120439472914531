import {
  Icon,
  Button,
  Box,
  Input,
  FormControl,
  Modal,
  useMediaQuery,
  useDisclose,
} from "native-base";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppContext } from "../../../contexts/app-context";
import { FontAwesome } from "@expo/vector-icons";
import { ScreensHeader } from "src/components/screens-header";
import { useUpdateUser } from "../queries/update-user";
import { Loading } from "src/components/loading";
import React from "react";
import { ErrorModel } from "src/components/error-modal";
import { SuccessModel } from "src/components/success-modal";
import { LayoutAuthenticated } from "src/components/layout-authenticated";
import { ArrowBack } from "src/components/arrow-back";
import { useUserInfo } from "../queries/user-info";
import { extractErrorMessages } from "src/utils/extractErrorMessages";

// Redirect login
import { LoginScreen } from "src/features/login/screens/login.screen";
import { LoginScreen as LoginScreenMobile } from "src/features/login/screens/login.screen.native";
import { Platform } from "react-native";
import AuthGuard from "src/guard/Guard";

// navigation & platform
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from 'src/navigator';

interface HelperStyle {
  color: string;
  fontSize: number;
  fontWeight: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Muy corto!")
    .max(50, "Muy largo!")
    .trim()
    .required("Este campo es obligatorio."),
  lastname: yup
    .string()
    .min(2, "Muy corto!")
    .max(100, "Muy largo!")
    .trim()
    .required("Este campo es obligatorio."),
  celular: yup
    .string()
    .typeError("El campo celular debe ser de tipo número")
    .min(10, "Celular no válido.")
    .max(15, "Muy largo.")
    .required("Este campo es obligatorio.")
    .trim()
    .transform((_, value) => {
      return Number.isNaN(value) ? "0" : value.toString();
    }),
  domicilio: yup.string().required("Este campo es obligatorio"),
});

export function EditProfileScreen() {
  const { user, token, editUser } = useAppContext();
  const { mutateAsync } = useUpdateUser(token);
  const { data } = useUserInfo(token);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<any>(null);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [isSmallScreen] = useMediaQuery({
    minWidth: 1024,
  });
  const { isOpen, onOpen, onClose } = useDisclose();
  const helperMessageStyle: HelperStyle = {
    color: "red.800",
    fontSize: 14,
    fontWeight: "semibold",
  };
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  async function onSubmit(data: any) {
    try {
      onOpen();
      const response = await mutateAsync({
        ...data,
      });

      setSuccessMessage(response.message);
      setShowSuccess(true);
    
      await editUser(data)

      navigation.navigate('home');
    
    } catch (_error: any) {
      setShowError(true);
      setErrorMessage(_error);
    }
  }

  function handleUserGender(): string {
    if (user.id_sexo == 1) {
      return "Masculino";
    } else if (user.id_sexo == 2) {
      return "Femenino";
    } else {
      return "No binario";
    }
  }
  
  if (!data) {
    if(Platform.OS === "web") return <LoginScreen />
    else return <LoginScreenMobile />
  }

  return(
  <AuthGuard>
    <LayoutAuthenticated>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        {isSubmitting ? (
          <Loading />
        ) : (
          <Modal.Content shadow={0} bg="contrastThreshold">
            <Modal.CloseButton
              mr="2"
              mt="2"
              _hover={{
                bg: "contrastThreshold",
              }}
            />
            {showError && (
              <Modal.Body>
                <ErrorModel
                  errorTitle="Error al Ingresar"
                  errorMessage={extractErrorMessages(errorMessage).join("\n ")}
                />
              </Modal.Body>
            )}
            {showSuccess && (
              <Modal.Body>
                <SuccessModel
                  successTitle="Actualizacion Exitosa!"
                  successMessage={successMessage}
                />
              </Modal.Body>
            )}
          </Modal.Content>
        )}
      </Modal>
      <ScreensHeader title="Editar Perfil" description="" />
      {!isSmallScreen && <ArrowBack route="home" />}
      <Box mt="5" px="4"mb={Platform.OS === "ios" ? '40' : '10'}>
        <FormControl mb="2" isRequired isInvalid={Boolean(errors.name)}>
          <FormControl.Label>Nombre</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Input
                borderColor="cyan.600"
                InputRightElement={
                  <Icon
                    as={<FontAwesome name="edit" />}
                    size={5}
                    mr="2"
                    color="#004C35"
                  />
                }
                autoCapitalize="none"
                onBlur={onBlur}
                onChangeText={onChange}
                onSubmitEditing={handleSubmit(onSubmit)}
                placeholder={data.user.name}
                value={value}
              />
            )}
            name="name"
            defaultValue={data.user.name}
          />
          <FormControl.HelperText _text={helperMessageStyle}>
            {errors.name?.message}
          </FormControl.HelperText>
        </FormControl>
        <FormControl mb="2" isRequired isInvalid={Boolean(errors.lastname)}>
          <FormControl.Label>Apellido</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Input
                borderColor="cyan.600"
                InputRightElement={
                  <Icon
                    as={<FontAwesome name="edit" />}
                    size={5}
                    mr="2"
                    color="#004C35"
                  />
                }
                placeholder={data.user.lastname}
                autoCapitalize="none"
                onBlur={onBlur}
                onChangeText={onChange}
                onSubmitEditing={handleSubmit(onSubmit)}
                value={value}
              />
            )}
            name="lastname"
            defaultValue={data.user.lastname}
          />
          <FormControl.HelperText _text={helperMessageStyle}>
            {errors.lastname?.message}
          </FormControl.HelperText>
        </FormControl>
        <FormControl mb="2">
          <FormControl.Label>DNI</FormControl.Label>
          <Input
            isReadOnly
            isDisabled
            borderColor="cyan.600"
            InputRightElement={
              <Icon
                as={<FontAwesome name="lock" />}
                size={5}
                mr="2"
                color="#C75786"
              />
            }
            placeholder={data.user.dni}
          />
        </FormControl>
        <FormControl mb="2" isRequired>
          <FormControl.Label>Domicilio</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Input
                borderColor="cyan.600"
                InputRightElement={
                  <Icon
                    as={<FontAwesome name="edit" />}
                    size={5}
                    mr="2"
                    color="#004C35"
                  />
                }
                autoCapitalize="none"
                placeholder={data.domicilio}
                onBlur={onBlur}
                onChangeText={onChange}
                onSubmitEditing={handleSubmit(onSubmit)}
                value={value}
              />
            )}
            name="domicilio"
            defaultValue={data.domicilio}
          />
          <FormControl.HelperText _text={helperMessageStyle}>
            {errors.domicilio?.message}
          </FormControl.HelperText>
        </FormControl>
        <FormControl mb="2" isRequired isInvalid={Boolean(errors.celular)}>
          <FormControl.Label>Celular</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Input
                borderColor="cyan.600"
                InputRightElement={
                  <Icon
                    as={<FontAwesome name="edit" />}
                    size={5}
                    mr="2"
                    color="#004C35"
                  />
                }
                placeholder={data.celular}
                autoCapitalize="none"
                onBlur={onBlur}
                onChangeText={onChange}
                onSubmitEditing={handleSubmit(onSubmit)}
                value={value}
              />
            )}
            name="celular"
            defaultValue={data.celular}
          />
          <FormControl.HelperText _text={helperMessageStyle}>
            {errors.celular?.message}
          </FormControl.HelperText>
        </FormControl>
        <FormControl mb="2">
          <FormControl.Label>Sexo</FormControl.Label>
          <Input
            isReadOnly
            isDisabled
            borderColor="cyan.600"
            InputRightElement={
              <Icon
                as={<FontAwesome name="lock" />}
                size={5}
                mr="2"
                color="#C75786"
              />
            }
            placeholder={handleUserGender()}
          />
        </FormControl>
        <FormControl mb="2">
          <FormControl.Label>Email</FormControl.Label>
          <Input
            isReadOnly
            isDisabled
            borderColor="cyan.600"
            InputRightElement={
              <Icon
                as={<FontAwesome name="lock" />}
                size={5}
                mr="2"
                color="#C75786"
              />
            }
            placeholder={data.user.email}
          />
        </FormControl>
        <FormControl mb="2">
          <FormControl.Label>Contraseña</FormControl.Label>
          <Input
            isReadOnly
            isDisabled
            borderColor="cyan.600"
            InputRightElement={
              <Icon
                as={<FontAwesome name="lock" />}
                size={5}
                mr="2"
                color="#C75786"
              />
            }
            placeholder="*******"
          />
        </FormControl>
        <Box alignItems="center" w="100%">
          <Button
            w="90%"
            mb={{ base: "8", lg: "4", xl: "4" }}
            mt={{ base: "8", lg: "4", xl: "4" }}
            colorScheme="pink"
            isDisabled={!isDirty}
            onPress={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            isLoadingText="Actualizando Informacion"
          >
            Guardar Cambios
          </Button>
        </Box>
      </Box>
    </LayoutAuthenticated>
  </AuthGuard>
  )
}
