import { HStack, Stack, View, Text } from 'native-base';
import { useRoute } from '@react-navigation/native';
import { useReclamoDetail } from '../queries/use-reclamo-details';
import { useAppContext } from 'src/contexts/app-context';
import { ArrowBack } from '../../../components/arrow-back';
import { ScreensHeader } from 'src/components/screens-header';
import { LayoutAuthenticated } from 'src/components/layout-authenticated';
import dayjs from 'dayjs';
import { MapDetailWeb } from '../components/reclamos-map-web';
import { CarrouselImageWeb } from '../components/carrousel-image-web';

export function ReclamoDetailScreen() {
  const route = useRoute();
  const { token } = useAppContext();
  const { id }: any = route.params;
  const { data } = useReclamoDetail(token, id);
  const photosLenght = Boolean(data?.data.fotos.length > 0);
  const coords = {
    lat: data?.data?.coordenadas?.latitud,
    lng: data?.data?.coordenadas?.longitud,
  };

  return (
    data && (
      <LayoutAuthenticated>
        <View>
          <ScreensHeader
            title="Detalle Reclamo"
            description={`Reclamo Numero ${id}`}
          />
          <ArrowBack route="mis-reclamos" />
          <Stack
            direction={{ base: 'column', xl: 'row' }}
            alignContent="center"
            bg={{ base: 'none', lg: 'none', xl: 'white' }}
            mt="4"
            rounded="xl"
            shadow={{ base: 'none', lg: 'none', xl: 8 }}
            h="80%"
            w={photosLenght ? '100%' : '50%'}
            p={{ base: 0, lg: '4', xl: '4' }}
          >
            <Stack
              direction="column"
              space={{ base: 0, lg: 6, xl: 10 }}
              alignContent="flex-start"
              p={{
                base: '2',
                xl: '5',
              }}
              mt={{ base: 5, md: 10, lg: 10, xl: 4 }}
              w={{ base: '100%', xl: photosLenght ? '50%' : '100%' }}
            >
              <HStack>
                <Text mr="2" bold fontSize="lg">
                  Número de Reclamo:
                </Text>
                <Text pt="0.5" fontSize="md" textAlign="start">
                  {data?.data?.id_contacto}
                </Text>
              </HStack>
              <HStack>
                <Text mr="2" bold fontSize="lg">
                  Fecha:
                </Text>
                <Text pt="0.5" fontSize="md" textAlign="start">
                  {dayjs(data?.data?.fecha_hora_contacto).format(
                    'dddd, DD/MM/YYYY, H:mm'
                  )}
                </Text>
              </HStack>
              <HStack>
                <Text mr="2" bold fontSize="lg">
                  Tipo de Problema:
                </Text>
                <Text pt="0.5" fontSize="md" textAlign="start">
                  {data?.data?.problemas?.descripcion}
                </Text>
              </HStack>
              <HStack>
                <Text mr="2" bold fontSize="lg">
                  Problema:
                </Text>
                <Text pt="0.5" fontSize="md" textAlign="start">
                  {data?.data?.problemas?.asunto}
                </Text>
              </HStack>
              <HStack>
                <Text mr="2" bold fontSize="lg">
                  Descripción:
                </Text>
                <Text pt="0.5" fontSize="md" textAlign="start">
                  {data?.data?.descripcion}
                </Text>
              </HStack>
              {Boolean(data?.data?.otro_dato_ubicacion) === true && (
                <HStack>
                  <Text mr="2" bold fontSize="lg">
                    Ubicacion:
                  </Text>
                  <Text pt="0.5" fontSize="md" textAlign="start">
                    {data?.data?.otro_dato_ubicacion}
                  </Text>
                </HStack>
              )}
              <HStack>
                <Text mr="2" bold fontSize="lg">
                  Estado:
                </Text>
                <Text pt="0.5" fontSize="md" textAlign="start">
                  {`${data?.data?.estado} - ${dayjs(
                    data?.data?.fechaEstado
                  ).format('dddd, DD/MM/YYYY, H:mm')}`}
                </Text>
              </HStack>
              {Boolean(
                data?.data?.coordenadas?.latitud &&
                  data?.data?.coordenadas?.longitud
              ) === true && <MapDetailWeb coordinates={coords} />}
            </Stack>
            {Boolean(photosLenght) && (
              <Stack
                direction="column"
                mt={{ base: 0, md: 10, lg: 10, xl: 12 }}
                ml={{ base: 0, xl: 12 }}
                h={{ base: '75%', md: '100%', lg: '100%', xl: '100%' }}
                w={{ base: '100%', lg: '100%', xl: '48%' }}
              >
                <CarrouselImageWeb images={data} />
              </Stack>
            )}
          </Stack>
        </View>
      </LayoutAuthenticated>
    )
  );
}
