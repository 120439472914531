import { useQuery } from '@tanstack/react-query';

function getReclamoDetail(token: string | null, reclamoId: any) {
  return async () => {
    const res = await fetch(
      `${process.env.EXPO_PUBLIC_API_URL}/v1/reclamo/${reclamoId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();

    return data;
  };
}

export function useReclamoDetail(token: string | null, reclamoId: string) {
  return useQuery(
    ['reclamo', token, reclamoId],
    getReclamoDetail(token, reclamoId),
    {
      enabled: !!token,
    }
  );
}
