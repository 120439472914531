import { Box, Image, Modal } from "native-base";

export function ImagePreviewModal({ imageUri }: { imageUri: string}) {
  return (
    <>
      <Modal.Content w="100%" bg="contrastThreshold">
        <Box>
          <Image
            resizeMode="contain"
            source={{ uri: imageUri }}
            alt={`Image ${imageUri}`}
            size={400}
          />
        </Box>
      </Modal.Content>
    </>
  );
}
