import React from "react";
import { Box, HStack, Pressable, Text, Image, Flex } from "native-base";
import FontAwesome from "@expo/vector-icons/FontAwesome";

import { useNavigation } from "@react-navigation/native";
import { RootStackParamList } from "../navigator";
import { StackNavigationProp } from "@react-navigation/stack";
import { useAppContext } from "src/contexts/app-context";
import {
  ImageBackground,
  StyleSheet,
  TouchableHighlight,
  Linking,
  Platform,
} from "react-native";

import logo from "../../assets/logo-header.png";

import Logo from "../../assets/logo-circle.png";
import Home from "../../assets/icons/home.png";
import Hammer from "../../assets/icons/hammer.png";
import List from "../../assets/icons/reclamos-list.png";
import Edit from "../../assets/icons/edit-person.png";
import Phone from "../../assets/icons/phone.png";
import Sign from "../../assets/icons/sign.png";
import TSeguro from "../../assets/icons/ts.png";
import { NavItem } from "./nav-item";

export const SidebarContent = () => {
  const { user, logout } = useAppContext();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const handleYTPress = async () => {
    Linking.openURL("https://www.youtube.com/channel/UCQIaXdSQ5qI3AUMItIErjjA");
  };
  const handleTwitterPress = async () => {
    Linking.openURL("https://twitter.com/municorrientes");
  };
  const handleWhatsAppPress = async () => {
    Linking.openURL(
      "https://api.whatsapp.com/send?phone=5493794341768&text=Hola"
    );
  };
  const handleInstaPress = async () => {
    Linking.openURL("https://www.instagram.com/municorrientes/");
  };
  const handleFacePress = async () => {
    Linking.openURL("https://www.facebook.com/corrientesmuni");
  };
  const handleMuniPress = async () => {
    Linking.openURL("https://ciudaddecorrientes.gov.ar/");
  };

  const handleItemPress = (screen: any) => () => {
    navigation.navigate(screen);
  };

  function handleLogout() {
    logout();
    navigation.navigate("home");
  }

  if (Platform.OS !== "web") {
    return null;
  }

  return (
    <Box
      position="relative"
      bg="#F6FFF8"
      paddingTop={6}
      paddingLeft={4}
      width={300}
    >
      <Pressable onPress={handleItemPress("home")}>
        <ImageBackground source={logo} style={styles.logo} />
      </Pressable>
      {user ? (
        <Box ml="4">
          <TouchableHighlight underlayColor="transparent">
            <NavItem image={Home} navigate={handleItemPress("home")}>
              Inicio
            </NavItem>
          </TouchableHighlight>
          <TouchableHighlight underlayColor="transparent">
            <NavItem image={Hammer} navigate={handleItemPress("tipo-problema")}>
              Nuevo Reclamo
            </NavItem>
          </TouchableHighlight>
          <TouchableHighlight underlayColor="transparent">
            <NavItem image={List} navigate={handleItemPress("mis-reclamos")}>
              Mis reclamos
            </NavItem>
          </TouchableHighlight>
          <TouchableHighlight underlayColor="transparent">
            <NavItem image={TSeguro} navigate={handleItemPress("transporte")}>
              Mi Transporte Seguro
            </NavItem>
          </TouchableHighlight>
          <TouchableHighlight underlayColor="transparent">
            <NavItem image={Phone} navigate={handleItemPress("contacto")}>
              Contacto
            </NavItem>
          </TouchableHighlight>
          <TouchableHighlight underlayColor="transparent">
            <NavItem image={Edit} navigate={handleItemPress("editar-perfil")}>
              Editar Perfil
            </NavItem>
          </TouchableHighlight>
          <TouchableHighlight underlayColor="transparent">
            <NavItem image={Sign} navigate={handleLogout}>
              Cerrar Sesión
            </NavItem>
          </TouchableHighlight>
        </Box>
      ) : (
        <Box>
          <TouchableHighlight underlayColor="transparent">
            <NavItem image={Home} navigate={handleItemPress("home")}>
              Inicio
            </NavItem>
          </TouchableHighlight>
          <TouchableHighlight underlayColor="transparent">
            <NavItem image={Sign} navigate={handleItemPress("login")}>
              Iniciar Sesion
            </NavItem>
          </TouchableHighlight>
        </Box>
      )}
      <Box bottom={0} marginTop="auto" justifyContent="center" mb="10">
        <Box ml="8" w="56">
          <HStack justifyContent="space-around">
            <FontAwesome
              name="whatsapp"
              size={22}
              color="#004c35"
              onPress={handleWhatsAppPress}
            />
            <FontAwesome
              name="youtube-play"
              size={22}
              color="#004c35"
              onPress={handleYTPress}
            />
            <FontAwesome
              name="instagram"
              size={22}
              color="#004c35"
              onPress={handleInstaPress}
            />
            <FontAwesome
              name="twitter"
              size={22}
              color="#004c35"
              onPress={handleTwitterPress}
            />
            <FontAwesome
              name="facebook-square"
              size={22}
              color="#004c35"
              onPress={handleFacePress}
            />
          </HStack>
        </Box>
        <Box ml="10" mt="4">
          <Flex justifyContent="center" direction="row">
            <Image src={Logo} alt="muni-logo" size="6" />
            <Pressable onPress={handleMuniPress}>
              <Text
                ml="3"
                mr="10"
                fontWeight="semibold"
                color="#004c35"
                textAlign="center"
              >
                ciudaddecorrientes.gov.ar
              </Text>
            </Pressable>
          </Flex>
        </Box>

        <Box ml="10" mt="2">
          <Text
            ml="3"
            mr="10"
            fontWeight="normal"
            color="#A3A3A3"
            textAlign="center"
          >
            Versión: {process.env.REACT_APP_VERSION}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  logo: {
    width: 230,
    height: 50,
    marginVertical: 20,
    marginLeft: 20,
    alignItems: "center",
    justifyContent: "center",
  },
});
