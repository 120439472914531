import { Box, Flex, HStack, Text, Image } from "native-base";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import React from "react";
import { Linking, Pressable } from "react-native";

import Logo from "../../assets/logo-circle.png";

const handleYTPress = async () => {
  Linking.openURL("https://www.youtube.com/channel/UCQIaXdSQ5qI3AUMItIErjjA");
};
const handleWhatsAppPress = async () => {
  Linking.openURL(
    "https://api.whatsapp.com/send?phone=5493794341768&text=Hola"
  );
};
const handleInstaPress = async () => {
  Linking.openURL("https://www.instagram.com/municorrientes/");
};
const handleFacePress = async () => {
  Linking.openURL("https://www.facebook.com/corrientesmuni");
};
const handleMuniPress = async () => {
  Linking.openURL("https://ciudaddecorrientes.gov.ar/");
};

export function FooterWeb() {
  return (
    <Flex
      bg="#F6FFF8"
      width="full"
      direction="row"
      justify="space-between"
      align="center"
      h="24"
    >
      <Box ml="10">
        <Flex justifyContent="center" direction="row" alignItems={'flex-end'}>
          <Image src={Logo} alt="muni-logo" size="6" />
          <Pressable onPress={handleMuniPress}>
            <Text
              ml="3"
              mr="10"
              fontWeight="semibold"
              color="#004c35"
              textAlign="center"
            >
              ciudaddecorrientes.gov.ar
            </Text>
          </Pressable>
        </Flex>
      </Box>

      <Text
        fontWeight="normal"
        color="#A3A3A3"
        textAlign="center"
      >
        Versión: {process.env.REACT_APP_VERSION}
      </Text>

      <Box mr="10" w="56">
        <HStack justifyContent="space-around">
          <FontAwesome
            name="whatsapp"
            size={22}
            color="#004c35"
            onPress={handleWhatsAppPress}
          />
          <FontAwesome
            name="youtube-play"
            size={22}
            color="#004c35"
            onPress={handleYTPress}
          />
          <FontAwesome
            name="instagram"
            size={22}
            color="#004c35"
            onPress={handleInstaPress}
          />
          <FontAwesome
            name="facebook-square"
            size={22}
            color="#004c35"
            onPress={handleFacePress}
          />
        </HStack>
      </Box>
    </Flex>
  );
}
