import {
  createDrawerNavigator,
  DrawerContentComponentProps,
} from "@react-navigation/drawer";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer, LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { Platform } from "react-native";
import { Center } from "native-base";

import { HomeScreen } from "./features/home/screens/home.screen";
import { EventosScreen } from "./features/eventos/screens/eventos.screen";
import {
  Evento,
  EventoDetailScreen,
} from "./features/eventos/screens/evento-detail.screen";
import { TramitesScreen } from "./features/tramites/screens/tramites.screen";
import { TramiteDetailScreen } from "./features/tramites/screens/tramite-detail.screen";
import { VecinosScreen } from "./features/vecinos/screens/vecinos.screen";
import { VecinoDetailScreen } from "./features/vecinos/screens/vecino-detail.screen";
import { ReclamosScreen } from "./features/reclamos/screens/reclamos.screen";
import { MisReclamosScreen } from "src/features/mis-reclamos/screens/mis-reclamos.screen";
import { ReclamoDetailScreen } from "src/features/mis-reclamos/screens/reclamos-details.screen";
import { NoticiasScreen } from "./features/noticias/screens/noticias.screen";
import {
  Noticia,
  NoticiaDetailScreen,
} from "./features/noticias/screens/noticia-detail.screen";
import { LoginScreen } from "./features/login/screens/login.screen";
import { TurismoScreen } from "./features/turismo/screens/turismo.screen";
import { useAppContext } from "./contexts/app-context";

import { UserDrawer } from "./components/user-drawer";
import { AnonymousDrawer } from "./components/anonymous-drawer";
import { Header } from "./components/header";
import { ContactoScreen } from "src/features/contacto/screens/contacto.screen";
import { ServiciosScreen } from "src/features/servicios/screens/servicios.screen";
import { ServicioDetailScreen } from "src/features/servicios/screens/servicio-detail.screen";
import { RegisterScreen } from "src/features/register/screens/register.screen";
import { ResetPasswordScreen } from "src/features/reset-password/screens/reset-password.screen";
import { Loading } from "./components/loading";
import { EditProfileScreen } from "src/features/editar-perfil/screens/edit-profile.screen";
import { TipoProblemaScreen } from "src/features/problemas/screens/tipo-problema.screen";
import { ProblemasScreen } from "src/features/problemas/screens/problemas.screen";
import TransporteScreen from "./features/transporte/screens/transporte.screen";
import TransporteNativeScreen from "./features/transporte/screens/transporte.native.screen";

export type RootStackParamList = {
  home: undefined;
  "editar-perfil": undefined;
  login: undefined;
  eventos: undefined;
  "evento-detail": {
    evento?: Evento;
    link?: string;
    title: string;
  };
  tramites: undefined;
  "tramite-detail": {
    link: string;
    title: string;
  };
  vecinos: undefined;
  "vecino-detail": {
    link: string;
    title: string;
  };
  problema: any;
  "tipo-problema": any;
  reclamos: any;
  "mis-reclamos": undefined;
  "reclamos-details": {
    id: number;
  };
  noticias: undefined;
  "noticia-detail": {
    noticia?: Noticia;
    link?: string;
    title: string;
  };
  servicios: undefined;
  "servicio-detail": {
    link: string;
    title: string;
  };
  turismo: undefined;
  contacto: undefined;
  register: undefined;
  "reset-password": undefined;
  transporte: undefined;
};

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator<RootStackParamList>();
const prefix = Linking.createURL("/");
const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: [prefix, "https://municipalidad-corrientes-app.vercel.app"],
  config: {
    screens: {
      root: {
        screens: {
          home: "",
          "editar-perfil": "editar-perfil",
          contacto: "contacto",
          eventos: "eventos",
          login: "login",
          noticias: "noticias",
          "noticia-detail": {
            path: "noticia/:title",
          },
          "tipo-problema": "tipo-problema",
          problema: "problema",
          reclamos: "reclamos",
          "mis-reclamos": "mis-reclamos",
          "reclamos-details": {
            path: "/mis-reclamos/:id",
          },
          register: "register",
          "reset-password": "reset-password",
          servicios: "servicios",
          tramites: "tramites",
          turismo: "turismo",
          vecinos: "vecinos",
          transporte: "transporte",
        },
      },
    },
  },
};

export function Navigator() {
  const { user } = useAppContext();

  const getDrawerContent = (props: DrawerContentComponentProps) => {
    if (user) {
      return <UserDrawer {...props} />;
    } else {
      return <AnonymousDrawer {...props} />;
    }
  };

  const Root = () => (
    <Stack.Navigator
      initialRouteName="home"
      screenOptions={{
        header: (props) =>
          Platform.OS !== "web" ? <Header {...props} /> : null,
      }}
    >
      <Drawer.Screen
        name="login"
        component={LoginScreen}
        options={{
          headerTitle: "Iniciar",
        }}
      />
      <Drawer.Screen
        name="editar-perfil"
        component={EditProfileScreen}
        options={{
          headerTitle: "Editar Perfil",
        }}
      />
      <Drawer.Screen
        name="register"
        component={RegisterScreen}
        options={{
          headerTitle: "Registrarse",
        }}
      />
      <Drawer.Screen
        name="reset-password"
        component={ResetPasswordScreen}
        options={{
          headerTitle: "Recuperar contraseña",
        }}
      />
      <Drawer.Screen
        name="home"
        component={HomeScreen}
        options={{ title: "Municipalidad de la Ciudad de Corrientes" }}
      />
      <Drawer.Screen
        name="eventos"
        component={EventosScreen}
        options={{
          headerTitle: "Eventos",
        }}
      />
      <Drawer.Screen
        name="evento-detail"
        component={EventoDetailScreen}
        options={({ route }: any) => ({ headerTitle: route.params?.title })}
      />
      <Drawer.Screen
        name="tramites"
        component={TramitesScreen}
        options={{
          headerTitle: "Tramites",
        }}
      />
      <Drawer.Screen
        name="tramite-detail"
        component={TramiteDetailScreen}
        options={({ route }: any) => ({ headerTitle: route.params?.title })}
      />
      <Drawer.Screen
        name="vecinos"
        component={VecinosScreen}
        options={{
          headerTitle: "Vecinos",
        }}
      />
      <Drawer.Screen
        name="vecino-detail"
        component={VecinoDetailScreen}
        options={({ route }: any) => ({ headerTitle: route.params?.title })}
      />
      <Drawer.Screen
        name="reclamos"
        component={ReclamosScreen}
        options={{
          headerTitle: "Nuevo Reclamo",
        }}
      />
      <Drawer.Screen
        name="mis-reclamos"
        component={MisReclamosScreen}
        options={{
          headerTitle: "Mis Reclamos",
        }}
      />
      <Drawer.Screen
        name="reclamos-details"
        component={ReclamoDetailScreen}
        options={{
          headerTitle: "Detalle del Reclamo",
        }}
      />
      <Drawer.Screen
        name="noticias"
        component={NoticiasScreen}
        options={{
          headerTitle: "Noticias",
          title: "Noticias",
        }}
      />
      <Drawer.Screen
        name="noticia-detail"
        component={NoticiaDetailScreen}
        options={({ route }: any) => ({ headerTitle: route.params?.title })}
      />
      <Drawer.Screen
        name="turismo"
        component={TurismoScreen}
        options={{ headerTitle: "Turismo CNQ" }}
      />
      <Drawer.Screen
        name="contacto"
        component={ContactoScreen}
        options={{
          headerTitle: "Contacto",
        }}
      />
      <Drawer.Screen
        name="servicios"
        component={ServiciosScreen}
        options={{
          headerTitle: "Servicios",
        }}
      />
      <Drawer.Screen
        name="servicio-detail"
        component={ServicioDetailScreen}
        options={({ route }: any) => ({ headerTitle: route.params?.title })}
      />
      <Drawer.Screen
        name="tipo-problema"
        component={TipoProblemaScreen}
        options={{
          headerTitle: "Nuevo Reclamo",
        }}
      />
      <Drawer.Screen
        name="problema"
        component={ProblemasScreen}
        options={{
          headerTitle: "Nuevo Reclamo",
        }}
      />

      <Drawer.Screen
        name="transporte"
        component={
          Platform.OS === "web" ? TransporteScreen : TransporteNativeScreen
        }
        options={{
          headerTitle: "Mi Transporte Seguro",
        }}
      />
    </Stack.Navigator>
  );

  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        formatter: () => "Municipalidad de Corrientes",
      }}
      fallback={
        <Center h="full">
          <Loading />
        </Center>
      }
    >
      <Drawer.Navigator
        initialRouteName="root"
        drawerContent={getDrawerContent}
        screenOptions={{
          headerShown: false,
        }}
      >
        <Drawer.Screen name="root" component={Root} />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}
