import { useForm, Controller } from 'react-hook-form';
import {
  StyleSheet,
  View,
  SafeAreaView,
  TouchableOpacity,
  Platform,
  KeyboardAvoidingView
} from 'react-native';
import {
  Box,
  Text,
  Input,
  FormControl,
  Button,
  Icon,
  Modal,
  useDisclose,
  Pressable,
  Stack,
  Image,
  ScrollView,
} from 'native-base';
import { Entypo, Fontisto, MaterialIcons } from '@expo/vector-icons';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useLogin } from '../queries/use-login';
import { useAppContext } from 'src/contexts/app-context';
import { RootStackParamList } from 'src/navigator';
import React from 'react';
import { Loading } from 'src/components/loading';
import { ErrorModel } from 'src/components/error-modal';
import MuniIcon from '../../../../assets/munibot_grande.png'

const schema = yup.object().shape({
  email: yup.string().transform((value) => value.trim()).email().required(),
  password: yup.string().required(),
});

export function LoginScreen() {
  const [showError, setShowError] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const { mutateAsync } = useLogin();
  const { login } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclose();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      fcm: 1,
      telefono: 1,
    },
    resolver: yupResolver(schema),
  });

  async function onSubmit(data: any) {
    try {
      onOpen();
      const response = await mutateAsync(data);
      login(response);
      navigation.navigate('home');
    } catch (_error: any) {
      setShowError(true);
      setErrorMessage(_error.error);
    } 
  }

  const autoCloseModal = () => {
    onOpen();
    setTimeout(() => {
      setShowError(false);
      onClose();
    }, 1000);
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "height" : "height"}
      style={{ flex: 1 }}  
    >
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        {isSubmitting ? (
          <Loading />
        ) : (
          showError && (
            <Modal.Content shadow={0} bg="white">
              <Modal.CloseButton
                mr="1"
                mt="1"
                _hover={{
                  bg: "contrastThreshold",
                }}
                onPress={function(){
                  setShowError(false);
                  onClose();
                }}
              />
              <Modal.Body>
                {showError && (
                  <ErrorModel
                    errorTitle="Error al Ingresar"
                    errorMessage={errorMessage}
                  />
                )}
              </Modal.Body>
              <Modal.Footer justifyContent="center" bg="white" p={4} width="100%">
                <TouchableOpacity
                  onPress={() => {
                    autoCloseModal();
                  }}
                >
                  <Text
                    onPress={() => navigation.navigate("reset-password")}
                    color="coolGray.600"
                    fontWeight="bold"
                  >
                    ¿Recuperar su contraseña?
                  </Text>
                </TouchableOpacity>
              </Modal.Footer>
            </Modal.Content>
          ))}
      </Modal>

      <View style={styles.container} >
        <Box w="100%" h={"full"}>
            <Box style={styles.content}>

              <View style={styles.formContainer}>

              <Stack width="full" justifyContent={"center"} alignItems={"center"} mb={8}>
                <Image source={MuniIcon} alt="muni-logo" size="180" resizeMode='contain'/>
              </Stack>
              
              <Stack width="full">
                <Text
                  color="#004C35"
                  fontSize="2xl"
                  fontWeight="semibold"
                  isTruncated
                  mb={'2'}
                  bgColor={"tomato"}
                >
                  Iniciar Sesión
                </Text>
              </Stack>

                <FormControl isRequired isInvalid={Boolean(errors.email)}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        size={"md"}
                        px="2"
                        InputLeftElement={
                          <Icon
                            as={<Entypo name="mail" />}
                            size={5}
                            mx="2"
                            color="#07888f"
                          />
                        }
                        placeholder="Email"
                        autoCapitalize="none"
                        onChangeText={onChange}
                        value={value}
                      />
                    )}
                    name="email"
                    defaultValue=""
                  />
                  <FormControl.ErrorMessage>
                    Email invalido
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={Boolean(errors.password)}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        size={"md"}
                        mt="2"
                        px="2"
                        placeholder="Contraseña"
                        InputLeftElement={
                          <Icon
                            as={<Fontisto name="locked" />}
                            size={5}
                            mx="2"
                            color="#07888f"
                          />
                        }
                        InputRightElement={
                          <Pressable onPress={() => setShow(!show)}>
                            <Icon
                              as={
                                <MaterialIcons
                                  name={show ? 'visibility' : 'visibility-off'}
                                />
                              }
                              size={5}
                              mx="2"
                              color="#07888f"
                            />
                          </Pressable>
                        }
                        secureTextEntry={show ? false : true}
                        onChangeText={onChange}
                        value={value}
                      />
                    )}
                    name="password"
                    defaultValue=""
                  />
                  <FormControl.ErrorMessage>
                    Contraseña Invalida
                  </FormControl.ErrorMessage>
                </FormControl>
                <Button
                  py={3}
                  style={styles.btnIngresar}
                  colorScheme="secondary"
                  color="white"
                  onPress={handleSubmit(onSubmit)}
                  isLoading={isSubmitting}
                  isLoadingText="Ingresando"
                >
                  <Text style={styles.textButton}>Ingresar</Text>
                </Button>
                <Button
                  variant="link"
                  style={styles.btnSecondary}
                  color="white"
                  onPress={() => navigation.navigate('reset-password')}
                >
                  ¿Ha olvidado su contraseña?
                </Button>
                <Button
                  py={3}
                  style={styles.btnSecondary}
                  _hover={{
                    bgColor: '#25B3D5',
                  }}
                  color="white"
                  onPress={() => navigation.navigate('register')}
                >
                  <Text style={styles.textButton}>Registrarme</Text>
                </Button>
              </View>
            </Box>
        </Box>
      </View>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white"
  },
  content: {
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1,
  },
  formContainer: {
    marginHorizontal: 12,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnIngresar: {
    width: '100%',
    marginTop: 30,
    color: '#e60000',
  },
  btnSecondary: {
    width: '100%',
    marginTop: 10,
  },
  textButton: {
    color: 'white',
  },
});
