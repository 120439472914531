import { useQuery } from '@tanstack/react-query';

function getTramites(text?: string) {
  return async () => {
    if (text) {
      const res = await fetch(
        `${process.env.EXPO_PUBLIC_WEB_API_URL}/buscar/${text}?filter[type]=tramite&sort=-fecha`
      );
      const { data } = await res.json();
      return data;
    } else {
      const res = await fetch(
        `${process.env.EXPO_PUBLIC_WEB_API_URL}/v1.0/tramites`
      );
      const data = await res.json();
      return data;
    }
  };
}

export function useTramites(text?: string) {
  return useQuery(['tramites', text], getTramites(text));
}
