import {
  DrawerContentScrollView,
  DrawerContentComponentProps,
  DrawerItem,
} from '@react-navigation/drawer';
import { View, StyleSheet, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Octicons, FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import { StackNavigationProp } from '@react-navigation/stack';

import logo from '../../assets/logo.png';
import { useAppContext } from '../contexts/app-context';
import { RootStackParamList } from '../navigator';
import { useState } from 'react';

export function UserDrawer(props: DrawerContentComponentProps) {
  const { logout } = useAppContext();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleItemPress = (screen: keyof RootStackParamList) => () => {
    if (!buttonDisabled) {
      setButtonDisabled(true);
      navigation.navigate(screen);
      setTimeout(() => {
        setButtonDisabled(false);
      }, 1000);
    }
  };

  function handleLogout() {
    if (!buttonDisabled) {
      setButtonDisabled(true); 
      logout();
      navigation.navigate('home');
      setTimeout(() => {
        setButtonDisabled(false);
      }, 1000); 
    }
  }

  return (
    <DrawerContentScrollView {...props}>
      <View style={styles.header}>
        <Image source={logo} style={styles.image} />
      </View>

      <DrawerItem
        label="Inicio"
        labelStyle={{
          color: '#5299c1',
          fontSize: 16
        }}
        icon={() => <MaterialIcons name="home" size={25} color="#5299c1" />}
        onPress={handleItemPress('home')}
        style={styles.option}
      />

      <DrawerItem
        label="Nuevo Reclamo"
        labelStyle={{
          color: '#5299c1',
          fontSize: 16,
        }}
        icon={() => <FontAwesome5 name="hammer" size={21} color="#5299c1" />}
        onPress={handleItemPress('tipo-problema')}
        style={styles.option}
      />

      <DrawerItem
        label="Mis Reclamos"
        labelStyle={{
          color: '#5299c1',
          fontSize: 16,
        }}
        icon={() => (
          <MaterialIcons name="playlist-add-check" size={25} color="#5299c1" />
        )}
        onPress={handleItemPress('mis-reclamos')}
        style={styles.option}
      />

      <DrawerItem
        label="Editar Perfil"
        labelStyle={{
          color: '#5299c1',
          fontSize: 16,
        }}
        icon={() => <FontAwesome5 name="user-edit" size={21}  color="#5299c1" />}
        onPress={handleItemPress('editar-perfil')}
        style={styles.option}
      />

      <DrawerItem
        label="Cerrar Sesión"
        labelStyle={{
          color: '#5299c1',
          fontSize: 16,
        }}
        icon={() => <Octicons name="sign-out" size={30} color="#c75786" />}
        onPress={handleLogout}
        style={styles.option}
      />
    </DrawerContentScrollView>
  );
}

const styles = StyleSheet.create({
  header: {
    height: 65,
    backgroundColor: '#F6FFF8',
    paddingVertical: 5,
    marginBottom: 12
  },
  image: {
    flex: 1,
    width: '100%',
    resizeMode: 'contain',
  },
  option: {
    borderBottomWidth: 0.5,
    borderBottomColor: '#F6FFF8'
  },
});
