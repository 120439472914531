import React from "react";
import { StyleSheet, View } from "react-native";
import { Box, Center } from "native-base";
import { useVecinos } from "../queries/use-vecinos";
import { VecinosItem } from "./vecinos-item";
import { Loading } from "src/components/loading";

export function VecinosData() {
  const { data: vecinos, isLoading } = useVecinos();

  return (
    <Box h={"full"} mb={16}>
      <View style={styles.TramitesList}>
        {isLoading ? (
           <Center mt={12}>
              <Loading />
            </Center>
          ):(
            vecinos?.map((vecino: any) => (
              <VecinosItem vecino={vecino} key={vecino.id} />
            ))
          )}
      </View>
    </Box>
  )
}

const styles = StyleSheet.create({
  colorSeccionBuscar: {
    backgroundColor: "transparent",
    height: 60,
  },
  TramitesList: {
    zIndex: 1,
    backgroundColor: "transparent",
  },
  button: {
    backgroundColor: "#ECF2EC",
    padding: 1,
  },
});
