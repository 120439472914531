import React from "react";
import {
  Box,
  HStack,
  Spacer,
  Text,
  View,
  ArrowForwardIcon,
  ArrowDownIcon,
} from "native-base";
import { useState } from "react";
import { TouchableHighlight, StyleSheet } from "react-native";

export function AccordionDetailsNative({ title, txt_content }: any) {
  const [showContent, setShowContent] = useState<any>(false);

  return (
    <View>
      <TouchableHighlight
        underlayColor="transparent"
        onPress={() => setShowContent(!showContent)}
      >
        <HStack
          space={[2, 3]}
          style={styles.boxItem}
          justifyContent="space-between"
        >
          {showContent ? (
            <ArrowDownIcon size="5" />
          ) : (
            <ArrowForwardIcon size="5" />
          )}
          <Text pl="2">{title}</Text>
          <Spacer />
        </HStack>
      </TouchableHighlight>
      <Box px="4">
        {showContent && (
          <div dangerouslySetInnerHTML={{ __html: txt_content }} />
        )}
      </Box>
    </View>
  );
}

export function AccordionDetails({ title, txt_content }: any) {
  const [showContent, setShowContent] = useState<any>(false);

  return (
    <View>
      <TouchableHighlight
        underlayColor="transparent"
        onPress={() => setShowContent(!showContent)}
      >
        <HStack
          space={[2, 3]}
          style={styles.boxItem}
          justifyContent="space-between"
        >
          {showContent ? (
            <ArrowDownIcon size="5" />
          ) : (
            <ArrowForwardIcon size="5" />
          )}
          <Text pl="2">{title}</Text>
          <Spacer />
        </HStack>
      </TouchableHighlight>
      <Box px="4">
        {showContent && (
          <div dangerouslySetInnerHTML={{ __html: txt_content }} />
        )}
      </Box>
    </View>
  );
}

const styles = StyleSheet.create({
  boxItem: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    marginVertical: 4,
    justifyContent: "space-between",
  },
});
