import { useQuery } from '@tanstack/react-query';

function getUserInfo(token: string | null) {
  return async () => {
    const res = await fetch(`${process.env.EXPO_PUBLIC_API_URL}/v1/user`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();

    // formatear telefono
    if(!data.data.celular){
      data.data.celular = data.data.telefono;
    }

    return data.data;
  };
}

export function useUserInfo(token: string | null) {
  return useQuery([token], getUserInfo(token), {
    enabled: !!token,
  });
}
