import { useQuery } from '@tanstack/react-query';

export interface GetSearchProps {
  queryParam: string;
  filterField: string; // Esto  será string
  filterParam: string;
  sort?: string; // También podria enumerarse
  pageNumber?: number;
}

async function getSearch({
  queryParam: queryParam = '',
  filterField,
  filterParam,
  sort = '-fecha',
  pageNumber = 1,
}: GetSearchProps) {
  if (queryParam.length >= 3) {
    const res = await fetch(
      `${
        process.env.EXPO_PUBLIC_WEB_API_URL
      }/buscar/${queryParam}?filter[${filterField}]=${filterParam}&sort=${sort}&page[number]=${
        pageNumber ? pageNumber : 1
      }`
    );
    const { data } = await res.json();
    return data;
  } else {
    ///Devuelve un arreglo vacío cuando no debe realizar una búsqueda.
    return [];
  }
}

export function useSearch(props: GetSearchProps) {
  return useQuery(['searchResults', props], () => getSearch(props));
}
