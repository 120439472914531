import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Box,
  HStack,
  Text,
  Flex,
  Menu,
  Tooltip,
  Heading,
  VStack,
  useMediaQuery,
} from "native-base";
import { Platform, Pressable } from "react-native";
import { useAppContext } from "src/contexts/app-context";
import Octicons from "@expo/vector-icons/Octicons";
import { RootStackParamList } from "../navigator";

export function ScreensHeader({ title, description }: any) {
  const { user, logout } = useAppContext();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [isSmallScreen] = useMediaQuery({
    minWidth: 1024,
  });

  function handleLogout() {
    logout();
    navigation.navigate("home");
  }

  if (Platform.OS !== "web") {
    return null;
  }

  return (
    <Box
      bg={{ base: "none"}}
      p="4"
      rounded="xl"
      mb={{ base: "3", lg: "16", xl: "16" }}
      shadow={{ base: "none", lg: 8, xl: 8 }}
    >
      <Flex
        px="1"
        py="4"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
      >
        <VStack>
          <Heading
            color="#004c35"
            fontSize={{ base: "lg", lg: "lg", xl: "xl" }}
            textTransform="uppercase"
          >
            {title}
          </Heading>
          <Text
            color="#004c35"
            fontSize="xl"
            textAlign="start"
            w={{ base: "90%", sm: "100%", lg: "100%", xl: "100%" }}
            fontWeight="thin"
          >
            {description}
          </Text>
        </VStack>
        {isSmallScreen && (
          <Box>
            {user && (
              <HStack mr={{ base: "0", xl: "16" }}>
                <Box px="4" py="3" mr="2" bg="gray.100" rounded="full">
                  <Menu
                    ml="2"
                    w="190"
                    trigger={(triggerProps) => {
                      return (
                        <Pressable
                          accessibilityLabel="More options menu"
                          {...triggerProps}
                        >
                          <Octicons
                            name="person-fill"
                            size={26}
                            color="#004c35"
                          />
                        </Pressable>
                      );
                    }}
                  >
                    <Menu.Item
                      _text={{
                        color: "#004c35",
                        fontWeight: "medium",
                        fontSize: 16,
                      }}
                      onPress={() => navigation.navigate("editar-perfil")}
                    >
                      Editar Perfil
                    </Menu.Item>
                  </Menu>
                </Box>
                <VStack mt="1">
                  <Text
                    mr="4"
                    textAlign="left"
                    color="#004c35"
                    fontWeight="medium"
                    fontSize={16}
                  >
                    {`${user.lastname}, ${user.name}`}
                  </Text>
                  <Text color="#004c35">{user.email}</Text>
                </VStack>
                <Box px="4" py="3">
                  <Tooltip label="Cerrar Sesión" open={500}>
                    <Pressable onPress={handleLogout}>
                      <Octicons name="sign-out" size={25} color="#c75786" />
                    </Pressable>
                  </Tooltip>
                </Box>
              </HStack>
            )}
          </Box>
        )}
      </Flex>
    </Box>
  );
}
