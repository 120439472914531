import { Box, Flex, HStack, Image } from "native-base";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import React from "react";
import { Linking, Pressable } from "react-native";

import Logo from "../../assets/icons/munibot.png";
import LogoWhatsapp from "../../assets/logo-circle.png";

const handleYTPress = async () => {
  Linking.openURL("https://www.youtube.com/channel/UCQIaXdSQ5qI3AUMItIErjjA");
};
const handleWhatsAppPress = async () => {
  Linking.openURL("https://api.whatsapp.com/send?phone=5493794341768&text=Hola, ");
};
const handleInstaPress = async () => {
  Linking.openURL("https://www.instagram.com/municorrientes/");
};
const handleFacePress = async () => {
  Linking.openURL("https://www.facebook.com/corrientesmuni");
};
const handleMuniPress = async () => {
  Linking.openURL("https://ciudaddecorrientes.gov.ar/");
};

export function FooterNative() {
  return (
    <Flex
      alignContent="center"
      p="2"
      py={3}
      justifyContent="center"
      bg="#F6FFF8"
      w="100%"
      bottom="0"
      position="absolute"
    >
      <Box w="full">
        <HStack justifyContent="space-evenly">
          <FontAwesome
            name="youtube-play"
            size={23}
            color="#004c35"
            onPress={handleYTPress}
          />
          <Pressable onPress={handleMuniPress}>
            <Image source={LogoWhatsapp} alt="muni-logo" size="6" />
          </Pressable>
          <Pressable onPress={handleWhatsAppPress}>
            <Image source={Logo} alt="muni-logo" size="7" />
          </Pressable>
          <FontAwesome
            name="instagram"
            size={23}
            color="#004c35"
            onPress={handleInstaPress}
          />
          <FontAwesome
            name="facebook-square"
            size={23}
            color="#004c35"
            onPress={handleFacePress}
          />
        </HStack>
      </Box>
    </Flex>
  );
}
