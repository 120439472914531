import {Box, HStack} from "native-base";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import React from "react";
import {Share} from "react-native"



export function ShareFooter({link, color} : any) {
    const onShare = async (link: any) => {
        try {
            const result = await Share.share({
                message: link,
            });
        } catch (error: any) {
            alert(error.message);
        }
    }

    return <Box p="2" >
        <HStack flex="1" alignItems="center" justifyContent="space-around">
            <FontAwesome
                name="share-alt"
                size={22}
                color={color}
                onPress={() => onShare(link)}
            />
            <FontAwesome
                name="whatsapp"
                size={22}
                color={color}
                onPress={() => onShare(link)}
            />
            <FontAwesome
                name="twitter"
                size={22}
                color={color}
                onPress={() => onShare(link)}
            />
            <FontAwesome
                name="facebook-square"
                size={22}
                color={color}
                onPress={() => onShare(link)}
            />
        </HStack>
    </Box>
}
