import { useQuery } from '@tanstack/react-query';

function getConfig(token: any) {
  return async () => {
    const response = await fetch(
      `${process.env.EXPO_PUBLIC_API_URL}/v1/config`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    if (data.error) {
      throw data.error;
    }

    return data;
  };
}

export function useConfig(token: string | null) {
  return useQuery(['config', token], getConfig(token), {
    enabled: !!token,
  });
}
