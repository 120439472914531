import { useMutation } from '@tanstack/react-query';

interface RegisterBody {
  email: string;
  name: string;
  password: string;
  dni: number;
  password_confirmation: number;
  telefono: number;
}

async function register(newUser: RegisterBody) {
  const res = await fetch(`${process.env.EXPO_PUBLIC_API_URL}/v1/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newUser),
  });
  const data = await res.json();
  
  if(!res.ok){
    return Promise.reject(data);
  };
  
  return data;
}

export function useRegister() {
  return useMutation(register);
}
