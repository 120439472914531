import { useQuery } from '@tanstack/react-query';

async function getEventos() {
  const res = await fetch(
    `${process.env.EXPO_PUBLIC_WEB_API_URL}/v1.0/eventos-culturales/0/200`
  );
  const data = await res.json();

  return data;
}

export function useEventos() {
  return useQuery(['eventos'], getEventos);
}
