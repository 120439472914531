import { isLoading } from "expo-font";
import { useState } from "react";
import { TransporteAdapter } from "../adapter/tranporte_adapter";

interface TransporteState {
  data: null | Transporte;
  isLoading: boolean;
  hasError: boolean;
  error: {
    msg: string;
    code: number;
  };
  isFetch: boolean;
}

interface Transporte {
  dominio: string;
  choferes: Array<any>;
  titular: string;
  empresa: string;
  habilitacion: string;
}

export const useTransporte = () => {
  const initialState: TransporteState = {
    data: null,
    error: {
      code: 0,
      msg: "",
    },
    isLoading: false,
    hasError: false,
    isFetch: false,
  };

  const [data, setData] = useState<TransporteState>(initialState);

  const handleFetchTranporte = async (dominio: string) => {
    try {
      setData({
        ...initialState,
        isLoading: true,
      });

      const res = await fetch(
        `${process.env.EXPO_PUBLIC_API_URL}/v1/tractas/dominio/${dominio}`
      );

      if (!res.ok) {
        setData({
          ...data,
          isFetch: true,
          isLoading: false,
          hasError: true,
          error: {
            code: res.status,
            msg: res.statusText,
          },
        });
        return;
      }

      const dataRes = await res.json();

      if (dataRes.data.error || dataRes.data.habilitacion.length <= 0) {
        setData({
          ...data,
          isFetch: true,
          isLoading: false,
          hasError: true,
          error: {
            code: res.status,
            msg: dataRes.data.error || "No habilitado",
          },
        });

        return;
      }

      setData({
        ...data,
        isFetch: true,
        isLoading: false,
        data: TransporteAdapter(dominio, dataRes.data),
      });
    } catch (error) {
      setData({
        ...data,
        isFetch: true,
        isLoading: false,
        hasError: true,
        error: {
          code: 500,
          msg: "Internal Server Error",
        },
      });
    }
  };

  const retryFetch = () => {
    setData(initialState);
  };

  return {
    handleFetchTranporte,
    data: data.data,
    isLoading: data.isLoading,
    hasError: data.hasError,
    error: data.error,
    fetch: data.isFetch,
    retryFetch,
  };
};
