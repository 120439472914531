//import liraries
import React, { useEffect, useState } from 'react';
//import components
import { useRoute, RouteProp } from '@react-navigation/native';
import { RootStackParamList } from 'src/navigator';
import { Box, Center, ScrollView, Text, View } from 'native-base';
import { Loading } from 'src/components/loading';
import { ShareFooter } from 'src/components/share-footer';
import { AccordionDetails } from 'src/components/accordion-details';

//Interface definition for a service
export interface Servicio {
  title: string;
  content: any;
  link_web: string;
  txt_items: Array<TextItem>;
}
interface TextItem {
  title: string;
  content: any;
}

// create a component
export function ServicioDetailScreen() {
  const [servicio, setServicio] = useState<Servicio>();
  const { params }: any = useRoute<RouteProp<RootStackParamList>>();

  useEffect(() => {
    const getService = async (url: any) => {
      const response = await fetch(url);
      const data = await response.json();
      setServicio({
        title: data.title,
        content: data.content,
        link_web: data.link,
        txt_items: data.txt_items,
      });
    };
    getService(params.link);
  }, [params]);

  return servicio ? (
    <ScrollView>
      <Box>
        <Text fontSize="2xl"> {servicio.title}</Text>
        <Box p="4">
          <div dangerouslySetInnerHTML={{ __html: servicio.content }} />
        </Box>
        {servicio.txt_items?.map((txt_item: TextItem, key: number) => (
          <View key={key}>
            <AccordionDetails
              title={txt_item.title}
              txt_content={txt_item.content}
            />
          </View>
        ))}
        <ShareFooter link={servicio.link_web} color="#ffc61a" />
      </Box>
    </ScrollView>
  ) : (
    <Center h="100%">
      <Loading />
    </Center>
  );
}
