import { Box, Text, View, Pressable, Image, Stack, ScrollView } from "native-base";

import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../../navigator";
import { HomeCardInfo } from "../screens/home.screen";

export function HomeCardsNative({ nativeCardInfo }: any) {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  return (
    <>
      {nativeCardInfo.map((cardInfo: HomeCardInfo, index: number) => (
        <Pressable
          key={index}
          onPress={() => navigation.navigate(cardInfo.path)}
        >
          <Stack
            w={"100%"}
            minH={78}
            maxH="auto"
            bg="white"
            rounded="md"
            mt="3"
            shadow={1}
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <View>
              <Image
                source={cardInfo.imagen}
                w={60}
                h={60}
                alt="utiles image"
                alignItems="center"
                justifyContent="center"
              />
            </View>
            <Box w="60%">
              <Text fontSize="2xl" fontWeight="semibold" color="#004c35">
                {cardInfo.titulo}
              </Text>
              <Text fontSize="sm" fontWeight="normal" color="#004c35">
                {cardInfo.descripcion}
              </Text>
            </Box>
          </Stack>
        </Pressable>
      ))}
    </>
  );
}
