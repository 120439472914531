import { useQuery } from '@tanstack/react-query';

async function getServicios() {
  const res = await fetch(
    `${process.env.EXPO_PUBLIC_WEB_API_URL}/v1.0/servicios`
  );
  const data = await res.json();

  return data;
}

export function useServicios() {
  return useQuery(['servicios'], getServicios);
}
